<template>
  <div>
    <!-- MCR X-End Details Collapsible -->
    <el-tooltip placement="top"
      :content="$t('connections.mcr-end-visibility', { operation: summaryShow ? $t('general.hide') : $t('general.show'), end })"
      :open-delay="500">
      <div class="summary-section-head"
        @click="summaryShow = !summaryShow">
        {{ $t('connections.mcr-end-details', { end }) }}
        <i class="fas fa-chevron-up action expand-arrow"
          :class="!summaryShow ? 'active' : ''"
          aria-hidden="true" />
      </div>
    </el-tooltip>

    <!-- MCR X-End Details Collapsible Content -->
    <el-collapse-transition>
      <div v-if="summaryShow">
        <!-- Auto-Configured Disclaimer -->
        <el-alert v-if="autoConfigured"
          type="info"
          :closable="false"
          class="max-width-450px mx-auto">
          <p>{{ $t('ports.autoconfig-mcr') }}</p>
          <p>{{ $t('connections.new-bgp-state', { state: bgpDefaultStateShutdown ? $t('connections.shutdown') : $t('connections.enabled') }) }}</p>
        </el-alert>

        <!-- Un-configured X-End Disclaimer -->
        <el-alert v-else-if="mcrInterfaces.length < 1"
          type="info"
          :title="$t('connections.end-not-configured', { end })"
          :closable="false"
          center
          show-icon
          class="max-width-450px mx-auto" />

        <template v-else>
          <div v-for="(iface, idx) in mcrInterfaces"
            :key="idx">
            <!-- Interface VLAN -->
            <simple-read-only-field v-if="iface.vlan"
              :label="$t('connections.interface-vlan')"
              :value="iface.vlan"
              :data-testid="`mcr-${lowerCaseEnd}-end-vlan`" />

            <!-- IP Addresses -->
            <simple-read-only-field v-if="iface.ipAddresses.length"
              :label="$t('connections.ip-addresses')"
              :value="iface.ipAddresses.join()"
              :data-testid="`mcr-${lowerCaseEnd}-end-ip-addresses`" />

            <!-- Static Routes -->
            <simple-read-only-field :label="$t('connections.static-routes')"
              :value="iface.ipRoutes.length"
              :data-testid="`mcr-${lowerCaseEnd}-end-static-routes`" />

            <!-- BGP Connections -->
            <simple-read-only-field :label="$t('connections.bgp-connections')"
              :value="iface.bgpConnections.length"
              :data-testid="`mcr-${lowerCaseEnd}-end-bgp-connections`" />

            <!-- NAT IP Addresses -->
            <simple-read-only-field :label="$t('connections.nat-ip-addresses')"
              :value="iface.natIpAddresses.length"
              :data-testid="`mcr-${lowerCaseEnd}-end-nat-ip-address`" />

            <!-- Separator -->
            <hr v-if="idx < mcrInterfaces.length - 1"
              class="max-width-450px">
          </div>
        </template>
      </div>
    </el-collapse-transition>
  </div>
</template>

<script>
import SimpleReadOnlyField from '@/components/ui-components/SimpleReadOnlyField.vue'

export default {
  name: 'McrSummary',

  components: {
    'simple-read-only-field': SimpleReadOnlyField,
  },

  props: {
    mcrInterfaces: {
      type: Array,
      required: true,
    },
    end: {
      type: String,
      required: true,
    },
    autoConfigured: {
      type: Boolean,
      required: false,
    },
    bgpDefaultStateShutdown: {
      type: Boolean,
      required: false,
    },
  },

  data() {
    return {
      summaryShow: false,
    }
  },

  computed: {
    lowerCaseEnd() {
      return this.end.toLowerCase()
    },
  },
}
</script>

<style lang="scss" scoped>
.summary-section-head {
  text-align: center;
  background: var(--button-default-background-color);
  border: var(--border-base);
  border-radius: var(--button-border-radius);
  border-color: var(--button-default-border-color);
  color: var(--button-default-font-color);
  margin: 1rem;
  transition: 0.1s;
  cursor: pointer;
  &:hover {
    color: var(--color-primary);
    border-color: var(--color-primary-light-7);
    background-color: var(--color-primary-light-9);
  }
  &:focus {
    outline: none;
  }
}

.expand-arrow {
  transition: transform 0.3s;
  &.active {
    transform: rotate(180deg);
  }
  &:focus {
    outline: none;
  }
}

.max-width-450px {
  max-width: 450px;
}
</style>
