<template>
  <div>
    <h4 class="text-align-center mt-1 mb-4">
      {{ $t('amsix.connection-details') }}
    </h4>

    <div class="amsix-field-container">
      <!-- Route Servers -->
      <el-form-item prop="serviceObj.bEnd.partnerConfig.routeserver"
        :label="$t('amsix.peer')"
        :label-width="labelWidth">
        <el-radio-group v-model="config.routeserver"
          name="peerGroup"
          data-testid="peerGroup">
          <el-radio-button :label="true">
            {{ $t('general.yes') }}
          </el-radio-button>
          <el-radio-button :label="false">
            {{ $t('general.no') }}
          </el-radio-button>
        </el-radio-group>
      </el-form-item>

      <!-- Peering Policy -->
      <el-form-item prop="serviceObj.bEnd.partnerConfig.peering_policy"
        :label="$t('connections.peering-policy')"
        :label-width="labelWidth"
        class="capitalize-text">
        <el-radio-group v-model="config.peering_policy"
          name="peerPolicy"
          data-testid="peerPolicy">
          <el-radio-button label="Open">
            {{ $t('connections.open') }}
          </el-radio-button>
          <el-radio-button label="Closed">
            {{ $t('connections.closed') }}
          </el-radio-button>
          <el-radio-button label="Selective">
            {{ $t('amsix.selective') }}
          </el-radio-button>
        </el-radio-group>
      </el-form-item>

      <!-- ASN -->
      <el-form-item prop="serviceObj.bEnd.partnerConfig.asn"
        :label-width="labelWidth"
        class="capitalize-text">
        <template #label>
          {{ $t('connections.asn') }}
          <el-tooltip placement="top"
            :content="$t('connections.asn-full')"
            :open-delay="500">
            <i class="fas fa-question-circle color-info popover-info-icon"
              aria-hidden="true" />
          </el-tooltip>
        </template>
        <el-input v-model="config.asn"
          type="text"
          data-demo="653333"
          name="asn"
          data-testid="asn" />
      </el-form-item>

      <!-- Technical Contact -->
      <el-form-item prop="serviceObj.bEnd.partnerConfig.tcp_id"
        :label-width="labelWidth"
        class="capitalize-text">
        <template #label>
          {{ $t('general.technical-contact') }}
          <el-tooltip placement="top"
            :content="buildContactTooltipFor($t('general.technical-contact'))"
            :open-delay="500">
            <i class="fas fa-question-circle color-info popover-info-icon"
              aria-hidden="true" />
          </el-tooltip>
        </template>
        <el-select v-model="config.tcp_id"
          :placeholder="$t('general.technical-contact')"
          name="techContact"
          data-testid="techContact"
          class="full-width">
          <el-option v-for="user in userlist"
            :key="user.personUid"
            :value="user.personUid"
            :label="user.firstName + ' ' + user.lastName + ' ' + user.email + (checkContactDetailsArePresentFor(user) ? '' : (' - ' + $t('users.missing-info')))"
            :disabled="!checkContactDetailsArePresentFor(user)" />
        </el-select>
      </el-form-item>

      <!-- NOC Contact -->
      <el-form-item prop="serviceObj.bEnd.partnerConfig.noc_id"
        :label-width="labelWidth"
        class="capitalize-text">
        <template #label>
          {{ $t('amsix.noc-contact') }}
          <el-tooltip placement="top"
            :content="buildContactTooltipFor($t('amsix.noc-contact'))"
            :open-delay="500">
            <i class="fas fa-question-circle color-info popover-info-icon"
              aria-hidden="true" />
          </el-tooltip>
        </template>
        <el-select v-model="config.noc_id"
          :placeholder="$t('amsix.noc-contact')"
          name="nocContact"
          data-testid="nocContact"
          class="full-width">
          <el-option v-for="user in userlist"
            :key="user.personUid"
            :value="user.personUid"
            :label="user.firstName + ' ' + user.lastName + ' ' + user.email + (checkContactDetailsArePresentFor(user) ? '' : (' - ' + $t('users.missing-info')))"
            :disabled="!checkContactDetailsArePresentFor(user)" />
        </el-select>
      </el-form-item>

      <!-- Phone -->
      <el-form-item prop="serviceObj.bEnd.partnerConfig.phone"
        :label-width="labelWidth"
        class="capitalize-text">
        <template #label>
          {{ $t('general.phone') }}
          <el-tooltip placement="top"
            :content="$t('billing-markets.phone-format')"
            :open-delay="500">
            <i class="fas fa-question-circle color-info popover-info-icon"
              aria-hidden="true" />
          </el-tooltip>
        </template>
        <el-input v-model="config.phone"
          type="text"
          data-demo="+61 7 12341234"
          placeholder="+61 7 40309999"
          name="phoneNumber"
          data-testid="phoneNumber" />
      </el-form-item>

      <!-- Policy URL -->
      <el-form-item prop="serviceObj.bEnd.partnerConfig.policy_url"
        :label="$t('connections.policy-url')"
        :label-width="labelWidth"
        class="capitalize-text">
        <el-input v-model="config.policy_url"
          type="text"
          data-demo="https://megaport.com"
          name="policyUrl"
          data-testid="policyUrl" />
      </el-form-item>

      <!-- Peering Email -->
      <el-form-item prop="serviceObj.bEnd.partnerConfig.peering_email"
        :label="$t('connections.peering-email')"
        :label-width="labelWidth"
        class="capitalize-text">
        <el-input v-model="config.peering_email"
          type="email"
          data-demo="peering@megaport.com"
          name="peeringEmail"
          data-testid="peeringEmail" />
      </el-form-item>

      <!-- Company URL -->
      <el-form-item prop="serviceObj.bEnd.partnerConfig.web_url"
        :label-width="labelWidth"
        class="capitalize-text">
        <template #label>
          {{ $t('connections.company-url') }}
          <el-tooltip placement="top"
            :content="$t('amsix.url-tooltip')"
            :open-delay="500">
            <i class="fas fa-question-circle color-info popover-info-icon"
              aria-hidden="true" />
          </el-tooltip>
        </template>
        <el-input v-model="config.web_url"
          type="url"
          data-demo="https://megaport.com"
          name="companyURL"
          data-testid="companyURL" />
      </el-form-item>

      <!-- Company Email -->
      <el-form-item prop="serviceObj.bEnd.partnerConfig.company_email"
        :label-width="labelWidth"
        class="capitalize-text">
        <template #label>
          {{ $t('connections.company-email') }}
          <el-tooltip placement="top"
            :content="$t('amsix.email-tooltip')"
            :open-delay="500">
            <i class="fas fa-question-circle color-info popover-info-icon"
              aria-hidden="true" />
          </el-tooltip>
        </template>
        <el-input v-model="config.company_email"
          type="email"
          data-demo="company@email.com"
          name="companyEmail"
          data-testid="companyEmail" />
      </el-form-item>

      <!-- Address 1 -->
      <el-form-item prop="serviceObj.bEnd.partnerConfig.address1"
        :label="$t('billing-markets.address')"
        :label-width="labelWidth"
        class="capitalize-text">
        <el-input v-model="config.address1"
          type="text"
          data-demo="742 Evergreen Tce"
          :placeholder="$t('connections.address-1')"
          name="streetAddress"
          data-testid="streetAddress" />
      </el-form-item>

      <!-- City -->
      <el-form-item prop="serviceObj.bEnd.partnerConfig.city"
        :label="$t('connections.city')"
        :label-width="labelWidth"
        class="capitalize-text">
        <el-input v-model="config.city"
          type="text"
          data-demo="Bedrock"
          name="city"
          data-testid="city" />
      </el-form-item>

      <!-- State -->
      <el-form-item prop="serviceObj.bEnd.partnerConfig.state"
        :label="config.country === 'CA' ? $t('billing-markets.province') : $t('billing-markets.state')"
        :label-width="labelWidth"
        class="capitalize-text">
        <el-select v-if="config.country === 'CA'"
          v-model="config.state"
          name="province"
          data-testid="state"
          class="full-width">
          <el-option value="ON"
            :label="$t('canada.on')" />
          <el-option value="QC"
            :label="$t('canada.qc')" />
          <el-option value="NS"
            :label="$t('canada.ns')" />
          <el-option value="NB"
            :label="$t('canada.nb')" />
          <el-option value="MB"
            :label="$t('canada.mb')" />
          <el-option value="BC"
            :label="$t('canada.bc')" />
          <el-option value="PE"
            :label="$t('canada.pe')" />
          <el-option value="SK"
            :label="$t('canada.sk')" />
          <el-option value="AB"
            :label="$t('canada.ab')" />
          <el-option value="NL"
            :label="$t('canada.nl')" />
        </el-select>
        <el-input v-else
          v-model="config.state"
          type="text"
          data-demo="FLINT"
          name="state"
          data-testid="state" />
      </el-form-item>

      <!-- Post Code -->
      <el-form-item prop="serviceObj.bEnd.partnerConfig.postcode"
        :label="$t('billing-markets.postcode')"
        :label-width="labelWidth"
        class="capitalize-text">
        <el-input v-model="config.postcode"
          type="text"
          data-demo="KA7 4UN"
          name="postcode"
          data-testid="postcode" />
      </el-form-item>

      <!-- Country -->
      <el-form-item prop="serviceObj.bEnd.partnerConfig.country"
        :label="$t('connections.country')"
        :label-width="labelWidth"
        class="capitalize-text">
        <el-select v-model="config.country"
          :placeholder="$t('amsix.select-country')"
          name="country"
          data-testid="country"
          filterable
          class="full-width">
          <el-option v-for="country in countryList"
            :key="country.code"
            :value="country.code"
            :label="country.name" />
        </el-select>
      </el-form-item>
    </div>
  </div>
</template>

<script>
import CountryMixin from '@/mixins/country.js'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'AmsIXConfig',

  mixins: [CountryMixin],

  props: {
    value: {
      type: Object,
      required: true,
    },
    source: {
      type: Object,
      required: true,
    },
  },

  emits: ['input'],

  data() {
    return {
      labelWidth: '450px',
      vlan: 0,
      // Initalize AmsIx config form fields
      config: {
        routeserver: null,
        peering_policy: null,
        policy_url: '',
        peering_email: '',
        asn: '',
        company_uid: '',
        tcp_id: '',
        noc_id: '',
        web_url: '',
        company_email: '',
        phone: '',
        address1: '',
        city: '',
        state: '',
        country: '',
        postcode: '',
      },
    }
  },

  computed: {
    ...mapState('Markets', ['supplies']),
    ...mapState('Users', ['users']),
    ...mapGetters('Auth', ['hasAuth']),
    ...mapGetters('Company', ['companyUid']),
    ...mapGetters('Marketplace', ['marketplaceProfile']),

    market() {
      if (this.source._location.market && this.supplies) {
        return this.supplies.find(supply => supply.region === this.source._location.market)
      } else {
        return null
      }
    },
    /**
     * Determine if form is complete
     */
    isComplete() {
      return this.config.routeserver !== undefined &&
        this.config.peering_policy &&
        this.config.policy_url !== '' &&
        this.config.peering_email !== '' &&
        this.config.asn !== '' &&
        this.config.tcp_id !== '' &&
        this.config.noc_id !== '' &&
        this.config.web_url !== '' &&
        this.config.company_email !== '' &&
        this.config.phone !== '' &&
        this.config.address1 !== '' &&
        this.config.city !== '' &&
        this.config.country !== '' &&
        this.config.postcode !== ''
        ? true
        : false
    },
    userlist() {
      return this.users.filter(user => user.active)
    },
  },

  watch: {
    'config.country'(newValue, oldValue) {
      if (newValue === 'CA' || oldValue === 'CA') {
        this.config.state = ''
      }
    },
    config: {
      handler() {
        // Emit every time config object has changed
        this.emitUpdatedPartnerConfig()
      },
      deep: true,
    },
  },

  created() {
    if (this.value.vlan) {
      this.vlan = this.value.vlan
    }
    if (this.value.partnerConfig) {
      // This strips out all the keys we are not interested in here and only picks the ones we are interested in
      // for this editing screen. Since this is emitted on the v-model, it automatically strips out the "isLive"
      // key, which means that we can pick that up before submitting.
      Object.keys(this.value.partnerConfig).forEach(key => {
        if (typeof this.config[key] !== 'undefined' && key !== 'complete') this.config[key] = this.value.partnerConfig[key]
      })
      this.emitUpdatedPartnerConfig()
    }
  },

  mounted() {
    // Set defaults wherever we can from existing data

    // Company UID
    this.config.company_uid = this.companyUid

    // Web URL
    if (this.marketplaceProfile?.www && !this.config.web_url)
      this.config.web_url = this.marketplaceProfile.www

    // Email
    if (this.marketplaceProfile?.email && !this.config.company_email)
      this.config.company_email = this.marketplaceProfile.email

    // Address 1
    if (!this.config.address1)
      this.config.address1 = this.marketplaceProfile?.street1 || this.market?.address1 || ''

    // City
    if (!this.config.city)
      this.config.city = this.marketplaceProfile?.city || this.market?.city || ''

    // State
    if (!this.config.state)
      this.config.state = this.marketplaceProfile?.state || this.market?.state || ''

    // Country
    if (!this.config.country) {
      if (this.marketplaceProfile?.country) {
        // Find the country code for the country string
        const mpCountry = this.countryList.find(country => country.name.toUpperCase() === this.marketplaceProfile.country.toUpperCase())
        if (mpCountry?.code) {
          this.config.country = mpCountry.code
        }
      }
      if (!this.config.country && this.market?.country) {
        this.config.country = this.market.country
      }
    }

    // Post Code
    if (!this.config.postcode) {
      this.config.postcode = this.marketplaceProfile?.postcode || this.market?.postcode || ''
    }
  },

  methods: {
    /**
     * Emit partnerConfig details back to CreateConnection
     */
    emitUpdatedPartnerConfig() {
      this.$emit('input', {
        vlan: this.vlan,
        productUid: this.value.productUid,
        partnerConfig: Object.assign({}, this.config, {
          connectType: 'AMSIX',
          complete: this.isComplete,
        }),
      })
    },
    checkContactDetailsArePresentFor(user) {
      return user.email && user.phone
    },
    buildContactTooltipFor(contactType) {
      const isAdmin = this.hasAuth('company_admin')
      const firstSentence = this.$t('connections.amsix-contact-phone', { contactType: contactType })
      const secondSentence = isAdmin ? this.$t('amsix.admin-phone-tooltip') : this.$t('amsix.phone-tooltip')

      return `${firstSentence} ${secondSentence}`
    },
  },
}
</script>

<style lang="scss" scoped>
.amsix-field-container .el-input {
  width: 450px;
}
</style>
