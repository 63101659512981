<template>
  <div>
    <h4 class="text-align-center mt-1 mb-2">
      {{ $t('alibaba.connection-details') }}
    </h4>

    <el-form-item prop="serviceObj.bEnd.partnerConfig.account_id"
      :label="$t('alibaba.account-id')"
      :label-width="labelWidth">
      <div class="flex-row-centered">
        <el-input v-model="config.account_id"
          type="text"
          data-demo="5234561292937849"
          name="alibabaAccountId"
          data-testid="alibabaAccountId" />

        <el-tooltip placement="top"
          :content="$t('alibaba.account-tooltip')"
          :open-delay="500">
          <i class="fas fa-question-circle color-info popover-info-icon-end"
            aria-hidden="true" />
        </el-tooltip>
      </div>
    </el-form-item>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
  },

  emits: ['input'],

  data() {
    return {
      labelWidth: '260px',
      vlan: 0,
      // Initalize Alibaba config form fields
      config: {
        account_id: null,
      },
    }
  },

  computed: {
    /**
     * Determine if form is complete
     */
    isComplete() {
      return !!(this.config.account_id && this.config.account_id.length >= 8)
    },
  },

  watch: {
    config: {
      handler() {
        // Emit every time config object has changed
        this.emitUpdatedPartnerConfig()
      },
      deep: true,
    },
  },

  created() {
    if (this.value.vlan) {
      this.vlan = this.value.vlan
    }

    if (this.value.partnerConfig) {
      // This strips out all the keys we are not interested in here and only picks the ones we are interested in
      // for this editing screen. Since this is emitted on the v-model, it automatically strips out the "isLive"
      // key, which means that we can pick that up before submitting.
      Object.keys(this.value.partnerConfig).forEach(key => {
        if (typeof this.config[key] !== 'undefined' && key !== 'complete') {
          this.config[key] = this.value.partnerConfig[key]
        }
      })

      this.emitUpdatedPartnerConfig()
    }
  },

  methods: {
    /**
     * Emit partnerConfig details back to CreateConnection
     */
    emitUpdatedPartnerConfig() {
      this.$emit('input', {
        vlan: this.vlan,
        productUid: this.value.productUid,
        partnerConfig: Object.assign({}, this.config, {
          connectType: 'ALIBABA',
          complete: this.isComplete,
          fixedBandwidths: this.value.partnerConfig.fixedBandwidths,
        }),
      })
    },
  },
}
</script>
