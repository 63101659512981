<template>
  <div>
    <h4 class="text-align-center mt-1">
      {{ $t('outscale.connection-details') }}
    </h4>

    <div class="bs-border-box d-flex flex-justify-center flex-wrap">
      <!-- Left column -->
      <div class="content-column">
        <div>
          <el-form-item prop="serviceObj.bEnd.partnerConfig.name"
            :label-width="labelWidth"
            class="show-non-editable">
            <template #label>
              {{ $t('outscale.connection-name') }}
              <el-tooltip placement="top"
                :content="$t('outscale.connection-name-tooltip')"
                :open-delay="500">
                <i class="fas fa-question-circle color-info popover-info-icon"
                  aria-hidden="true" />
              </el-tooltip>
            </template>
            <el-input v-model="config.name"
              type="text"
              data-demo="Example Outscale title"
              data-testid="outscaleConnectionName" />
          </el-form-item>

          <el-form-item prop="serviceObj.bEnd.partnerConfig.account_id"
            class="show-non-editable"
            :label-width="labelWidth">
            <template #label>
              {{ $t('outscale.account-id') }}
              <el-tooltip placement="top"
                :content="$t('outscale.account-id-tooltip')"
                :open-delay="500">
                <i class="fas fa-question-circle color-info popover-info-icon"
                  aria-hidden="true" />
              </el-tooltip>
            </template>
            <el-input v-model="config.account_id"
              type="text"
              data-demo="187425920085"
              data-testid="outscaleAccountId"
              class="full-width" />
          </el-form-item>

          <el-form-item prop="serviceObj.bEnd.partnerConfig.customer_asn"
            :label-width="labelWidth"
            class="long-error">
            <template #label>
              {{ $t('connections.customer-asn') }}
              <el-tooltip placement="top"
                :content="isMcr ? $t('connections.asn-from-mcr') : $t('aws.bgp-asn')"
                :open-delay="500">
                <i class="fas fa-question-circle color-info popover-info-icon"
                  aria-hidden="true" />
              </el-tooltip>
            </template>
            <el-input v-model="config.customer_asn"
              type="text"
              :disabled="isMcr"
              data-demo="65333"
              data-testid="customerAsn" />
          </el-form-item>
        </div>
      </div>

      <!-- Right column -->
      <div class="content-column">
        <div>
          <el-form-item prop="serviceObj.bEnd.partnerConfig.auth_key"
            :label-width="labelWidth">
            <template #label>
              {{ $t('aws.bgp-key') }}
              <el-tooltip placement="top"
                :content="$t('aws.bgp-key-tooltip')"
                :open-delay="500">
                <i class="fas fa-question-circle color-info popover-info-icon"
                  aria-hidden="true" />
              </el-tooltip>
            </template>
            <el-input v-model="config.auth_key"
              type="text"
              data-testid="bgpAuthKey" />
          </el-form-item>

          <el-form-item prop="serviceObj.bEnd.partnerConfig.customer_ip_address"
            :label="$t('connections.customer-ip')"
            :label-width="labelWidth"
            class="long-error">
            <el-input v-model="config.customer_ip_address"
              type="text"
              placeholder="e.g. 192.0.1.0/30"
              data-demo="172.0.0.1/30"
              data-testid="customerIpAddress" />
          </el-form-item>

          <el-form-item prop="serviceObj.bEnd.partnerConfig.provider_ip_address"
            :label="$t('connections.outscale-ip')"
            :label-width="labelWidth"
            class="long-error">
            <el-input v-model="config.provider_ip_address"
              type="text"
              placeholder="e.g. 192.0.1.0/30"
              data-demo="172.0.0.1/30"
              data-testid="providerIpAddress" />
          </el-form-item>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MEGAPORT_ASN } from '@/Globals'

export default {
  name: 'OutscaleConfig',

  props: {
    value: {
      type: Object,
      required: true,
    },
    aEndConnection: {
      type: Object,
      required: false,
      default: undefined,
    },
  },

  emits: ['input'],

  data() {
    return {
      vlan: 0,
      config: {
        name: null,
        account_id: null,
        type: 'private',
        customer_asn: null,
        auth_key: null,
        customer_ip_address: null,
        provider_ip_address: null,
      },
    }
  },

  computed: {
    /**
     * Determine if form is complete
     */
    isComplete() {
      return !!(this.config.account_id && this.config.customer_asn)
    },
    /**
     * Check if we're on an MCR to disable ASN field
     */
    isMcr() {
      return this.aEndConnection?.productType === this.G_PRODUCT_TYPE_MCR2
    },
    labelWidth() {
      switch (this.$i18n.locale) {
        case 'de':
          return '250px'
        case 'es':
          return '260px'
        default:
          return '245px'
      }
    },
  },

  watch: {
    config: {
      handler() {
        // Emit every time config object has changed
        this.emitUpdatedPartnerConfig()
      },
      deep: true,
    },
  },

  created() {
    if (this.value.vlan) {
      this.vlan = this.value.vlan
    }
    if (this.value.partnerConfig) {
      // This strips out all the keys we are not interested in here and only picks the ones we are interested in
      // for this editing screen. Since this is emitted on the v-model, it automatically strips out the "isLive"
      // key, which means that we can pick that up before submitting.
      Object.keys(this.value.partnerConfig).forEach(key => {
        if (typeof this.config[key] !== 'undefined' && key !== 'complete') {
          this.config[key] = this.value.partnerConfig[key]
        }
      })
      this.emitUpdatedPartnerConfig()
      if (this.isMcr) {
        this.autoFillFields()
      }
    }
  },

  methods: {
    /**
     * Emit partnerConfig details back to CreateConnection
     */
    emitUpdatedPartnerConfig() {
      this.$emit('input', {
        vlan: this.vlan,
        productUid: this.value.productUid,
        partnerConfig: Object.assign({}, this.config, {
          connectType: 'OUTSCALE',
          complete: this.isComplete,
        }),
      })
    },
    /**
     * Autofill customer_asn field when using MCR
     */
    autoFillFields() {
      if (this.aEndConnection.resources) {
        this.config.customer_asn = this.aEndConnection.resources.virtual_router?.mcrAsn.toString()
      } else if (this.aEndConnection.config) {
        this.config.customer_asn = this.aEndConnection.config.mcrAsn?.toString() || MEGAPORT_ASN.toString()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.content-column {
  padding: 1rem;
  box-sizing: border-box;
  display: flex;
  width: min-content;
  min-width: 510px;

  > div {
    flex: 1 1 auto;
  }
}
</style>
