<template>
  <div>
    <h4 class="text-align-center mt-1">
      {{ $t('oracle.configuration') }}
    </h4>

    <el-form-item ref="serviceKeyInput"
      prop="serviceObj.bEnd.partnerConfig.virtualCircuitId"
      :label="$t('oracle.virtual-circuit-id')"
      label-position="top">
      <el-input v-model="key"
        v-loading="checking"
        type="text"
        placeholder="ocid1.virtualcircuit.oc1.phx.aaaaaaaapsokflwszxk3c2vhsyj5pkas3gmh3zngyxx7zj6yxj2stgeofk5q"
        data-demo="ocid1.virtualcircuit.oc1.phx.aaaaaaaapsokflwszxk3c2vhsyj5pkas3gmh3zngyxx7zj6yxj2stgeofk5q"
        name="oracleKey"
        data-testid="oracleKey" />
    </el-form-item>

    <div class="text-align-center height-2-2rem">
      <div v-if="knownKey"
        class="color-success">
        <i class="fas fa-check-circle"
          aria-hidden="true" /> {{ $t('connections.valid-service-key') }}
      </div>
      <div v-else-if="checking"
        class="color-warning">
        {{ $t('connections.verifying-key') }}
      </div>
      <div v-else
        class="color-danger">
        {{ error }}
      </div>
    </div>

    <!-- <template v-if="hasKey"> -->
    <el-collapse-transition>
      <div v-if="knownKey">
        <el-form-item :label="$t('connections.oracle-ports')"
          prop="serviceObj.bEnd.productUid">
          <div class="port-select-holder">
            <el-table ref="portSelectTable"
              :data="ports"
              highlight-current-row
              max-height="450"
              :show-header="true"
              :empty-text="message"
              row-class-name="port-row"
              class="port-select-table full-width collapsed-border hide-table-header"
              @row-click="handlePortSelect">
              <el-table-column property="port"
                label="Port"
                min-width="100">
                <template #default="{row}">
                  <div class="port-cell"
                    data-testid="port-cell"
                    :data-service-name="row.title"
                    :data-location="row._location && row._location.name"
                    :data-diversity-zone="row.diversityZone || 'none'">
                    <div class="height-2-5rem">
                      <mu-mega-icon icon="PORT"
                        class="icon" />
                    </div>
                    <div>
                      <div class="port-header">
                        {{ row.name }}
                      </div>
                      <div>{{ row.description }}</div>
                    </div>
                    <div v-if="row.diversityZone"
                      class="ml-auto">
                      <i class="far fa-dot-circle diversity-icon"
                        :class="`diversity-color-${row.diversityZone}`"
                        :title="$t(`services.${row.diversityZone}-zone`)" />
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-form-item>
      </div>
    </el-collapse-transition>

    <p v-if="!ports || !ports.length">
      <br>{{ messageString[0] }}
      <u>
        <router-link to="/marketplace/oracle">{{ messageString[1] }}</router-link>
      </u>
    </p>
  </div>
</template>

<script>
import captureSentryError from '@/utils/CaptureSentryError.js'
import { mapGetters } from 'vuex'
import sdk from '@megaport/api-sdk'

export default {
  props: {
    bEnd: {
      type: Object,
      default() {
        return { }
      },
    },
  },

  data() {
    return {
      hasKey: true,
      key: null,
      knownKey: null,
      checking: false,
      productUid: null,
      error: null,
    }
  },

  computed: {
    ...mapGetters('Services', ['targetPorts']),

    ports() {
      if (!this.knownKey?.megaports) return []

      return this.knownKey.megaports
        .filter(port => !port.vxc)
        .map(keyPort => {
          const targetPort = this.targetPorts.find(p => p.productUid === keyPort.productUid)

          if (targetPort) return { ...targetPort, ...keyPort }
          return keyPort
        })
    },
    message() {
      if (!this.knownKey) return ''
      if (!this.ports.length) {
        return this.$t('connections.no-oracle-ports')
      }
      return ''
    },
    messageString() {
      const translated = this.$t('connections.list-all-oracle-ports')
      const split = translated.split('|')
      if (split.length !== 2) {
        console.error(`Error splitting translated string ${translated} into two parts`)
        return null
      }
      return split
    },
  },

  watch: {
    productUid() {
      this.emitUpdate()
    },
    key() {
      if (!this.key.includes('virtualcircuit')) {
        this.productUid = null
        this.knownKey = null
        this.emitUpdate()
        return
      }
      this.key = this.key.trim()
      this.checkKey()
    },
  },
  created() {
    if (this.bEnd.partnerConfig && this.bEnd.partnerConfig.virtualCircuitId) {
      this.key = this.bEnd.partnerConfig.virtualCircuitId
    }
  },

  methods: {
    emitUpdate() {
      this.$emit('update', {
        productUid: this.productUid,
        vlan: this.knownKey ? this.knownKey.vlan : null,
        partnerConfig: {
          bVlan: this.knownKey ? this.knownKey.vlan : null,
          connectType: 'ORACLE',
          virtualCircuitId: this.key,
          validKey: this.knownKey !== null,
          maxRateLimit: this.knownKey ? this.knownKey.bandwidth : null,
        },
      })
      this.$refs.serviceKeyInput?.elForm.validateField('serviceObj.bEnd.partnerConfig.virtualCircuitId')
    },
    handlePortSelect(val) {
      // If nothing was selected or the same item as last time was clicked, then deselect.
      if (!val || (this.productUid && this.productUid === val.productUid)) {
        this.$refs.portSelectTable.setCurrentRow()
        this.productUid = null
      } else {
        this.productUid = val.productUid
      }
    },
    checkKey() {
      this.productUid = null
      this.knownKey = null

      this.checking = true
      sdk.instance
        .product()
        .oracle(this.key)
        .then(oracleData => {
          this.checking = false
          this.knownKey = oracleData

          if (oracleData.megaports && this.bEnd.productUid) {
            this.productUid = this.bEnd.productUid
          }

          this.error = null

          this.$nextTick(() => {
            // See if the selected port was in the list, and if so, select it.
            if (this.knownKey.megaports && this.productUid && this.$refs.portSelectTable) {
              const index = this.ports.findIndex(port => {
                return port.productUid === this.productUid
              })
              if (index !== -1) {
                // Set the current row
                this.$refs.portSelectTable.setCurrentRow(this.ports[index])
                // Scroll the selected row to visible - nasty hacky way to do it, but it works
                const targetTop = this.$refs.portSelectTable.$el.querySelectorAll('.el-table__body tr')[index].getBoundingClientRect().top
                const containerTop = this.$refs.portSelectTable.$el.querySelector('.el-table__body').getBoundingClientRect().top
                const scrollParent = this.$refs.portSelectTable.$el.querySelector('.el-table__body-wrapper')
                scrollParent.scrollTop = targetTop - containerTop
              } else {
                this.productUid = null
              }
            }
          })

          this.emitUpdate()
        })
        .catch(e => {
          this.knownKey = null
          this.checking = false
          this.error = this.$t('validations.virtual-circuit-invalid')
          this.emitUpdate()
          // 400 for bad key, anything else should be reported
          if (e.status !== 400) {
            captureSentryError(e)
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.port-select-holder {
  background-color: #fbfbfb;
  border: 1px solid var(--card-border-color);
  border-radius: var(--border-radius-base);
  padding: 0.5rem;
  margin-top: 1rem;
  clear: both;
}
.is-error .port-select-holder {
  border-color: var(--color-danger);
}
.is-success .port-select-holder {
  border-color: var(--color-success);
}
svg.icon {
  display: inline-block;
  color: var(--color-text-regular);
  width: 2.5rem;
  height: 2.5rem;
  fill: currentColor;
}
.port-cell svg {
  margin-right: 0.5rem;
}
</style>
<style lang="scss">
.port-select-table table tr.port-row {
  background-color: #fbfbfb;
  &:hover > td {
    background-color: #fbfbfb;
  }
}
.port-select-table.el-table td {
  padding: 0;
  border: 0;
}
.port-select-table .port-cell {
  display: flex;
  align-items: center;
  background-color: var(--color-white);
  border: 1px solid var(--card-border-color);
  border-radius: var(--border-radius-base);
  padding: 0.5rem;
  margin: 0.25rem 0;
}
.port-cell {
  background-color: var(--color-white);
  word-break: normal;
  word-wrap: break-word;
  &:hover {
    border-color: var(--color-primary-light-5);
    background-color: var(--color-primary-light-9);
  }
  .port-header {
    font-weight: 700;
  }
}
.current-row .port-cell {
  .port-header {
    color: var(--color-primary);
  }
  border-color: var(--color-primary);
  background-color: var(--color-primary-light-8);
  svg.icon {
    color: var(--color-primary);
    fill: currentColor;
  }
}
.port-select-table .el-table__body tr.current-row > td {
  background-color: #fbfbfb;
}
</style>
