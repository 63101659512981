<template>
  <div class="content-column">
    <h4 class="text-align-center mt-1">
      {{ $t('general.select-type-location', { type: $t('productNames.ix') }) }}
    </h4>

    <div v-loading="!ixLocations">
      <el-form ref="targetSelectIxForm"
        :model="targetSelectIxForm"
        :rules="targetSelectIxRules">
        <el-form-item :label="$t('general.select-type-location', { type: $t('productNames.ix') })"
          prop="ixType"
          data-testid="location-select-holder">
          <div v-if="ixLocations.length > 10 || textFilter.length > 0">
            <el-input v-model="textFilter"
              :placeholder="$t('general.search')"
              class="dont-color flex-1"
              clearable
              @blur="trackSearch">
              <template #prefix>
                <i class="fas fa-search halfway ml-4px"
                  aria-hidden="true" />
              </template>
            </el-input>
          </div>

          <div class="ix-location-select-holder">
            <el-table ref="ixSelectTable"
              :data="ixLocations"
              highlight-current-row
              max-height="450"
              :show-header="true"
              :empty-text="$t('general.no-matching-locations')"
              row-class-name="ix-location-row"
              class="ix-location-select-table full-width collapsed-border hide-table-header"
              @row-click="handleIxSelect">
              <el-table-column property="company"
                :label="$t('menu.company')"
                min-width="100">
                <template #default="{row}">
                  <div class="ix-location-cell"
                    data-testid="ix-location">
                    <div class="height-2-5rem">
                      <mu-mega-icon icon="IX"
                        class="icon" />
                    </div>
                    <div>
                      <div class="ix-location-header">
                        {{ row.description || row.name }}
                      </div>
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import stableStringify from 'fast-json-stable-stringify'
import ConnectionValidator from '@/components/ConnectionValidations.js'
import { deepClone } from '@/helpers.js'
import { captureEvent } from '@/utils/analyticUtils'

export default {
  props: {
    value: {
      type: Object,
      required: false,
      default: null,
    },
    aEndLoc: {
      type: Number,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      textFilter: '',
      trackedSearches: new Set(),
      ixLocs: [],
      targetSelectIxForm: {
        ixType: null,
      },
    }
  },

  computed: {
    targetSelectIxRules() {
      const cv = new ConnectionValidator(this)
      return cv.validationForType('target-select-ix')
    },
    ixLocations() {
      if (!this.aEndLoc) return []

      if (this.ixLocs && this.ixLocs.length > 0) {
        if (this.textFilter.length > 0) {
          const filteredLocations = this.ixLocs.filter(loc => {
            return loc.name.toLowerCase().includes(this.textFilter.toLowerCase())
          })
          return filteredLocations
        }
        return this.ixLocs
      }
      this.updateIxLocations()

      return this.ixLocs
    },
  },

  created() {
    if (this.value) {
      this.targetSelectIxForm.ixType = this.value
    }
  },

  methods: {
    validate() {
      this.$refs.targetSelectIxForm.validate(() => {
        // empty function is intentional
      })
    },
    trackSearch() {
      if (this.textFilter && !this.trackedSearches.has(this.textFilter)) {
        captureEvent(`create-ix.select-port.search`, { query: this.textFilter })
        this.trackedSearches.add(this.textFilter)
      }
    },
    handleIxSelect(val) {
      // If nothing was selected or the same item as last time was clicked, then deselect.

      if (!val || (this.targetSelectIxForm.ixType && stableStringify(this.targetSelectIxForm.ixType) === stableStringify(val))) {
        this.$refs.ixSelectTable.setCurrentRow()
        this.targetSelectIxForm.ixType = null
      } else {
        this.targetSelectIxForm.ixType = val
      }

      this.validate()

      // Emit whether it is valid or not
      this.$emit('input', deepClone(this.targetSelectIxForm.ixType))
    },

    updateIxLocations() {
      this.$store
        .dispatch('IXTypes/getIxType', this.aEndLoc)
        .then(locs => {
          this.ixLocs = locs

          this.$nextTick(() => {
            // See if the selected port was in the list, and if so, select it.
            if (this.targetSelectIxForm.ixType && this.$refs.ixSelectTable) {
              const index = this.ixLocations.findIndex(ix => {
                return ix.name === this.targetSelectIxForm.ixType.name
              })
              if (index !== -1) {
                // Set the current row
                this.$refs.ixSelectTable.setCurrentRow(this.ixLocations[index])
                // Scroll the selected row to visible - nasty hacky way to do it, but it works
                const targetTop = this.$refs.ixSelectTable.$el.querySelectorAll('.el-table__body tr')[index].getBoundingClientRect().top
                const containerTop = this.$refs.ixSelectTable.$el.querySelector('.el-table__body').getBoundingClientRect().top
                const scrollParent = this.$refs.ixSelectTable.$el.querySelector('.el-table__body-wrapper')
                scrollParent.scrollTop = targetTop - containerTop
              } else {
                this.productUid = null
              }
            }
          })
        })
        .catch(err => {
          console.error(err)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.content-column {
  padding: 1rem;
  box-sizing: border-box;
  width: min-content;
  min-width: 430px;
}
.ix-location-select-holder {
  background-color: #fbfbfb;
  border: 1px solid var(--card-border-color);
  border-radius: var(--border-radius-base);
  padding: 0.5rem;
  margin-top: 1rem;
  clear: both;
}
.is-error .ix-location-select-holder {
  border-color: var(--color-danger);
}
.is-success .ix-location-select-holder {
  border-color: var(--color-success);
}
svg.icon {
  display: inline-block;
  color: var(--color-text-regular);
  width: 2.5rem;
  height: 2.5rem;
  fill: currentColor;
}
.ix-location-cell svg {
  margin-right: 0.5rem;
}
</style>

<style lang="scss">
.ix-location-select-table table tr.ix-location-row {
  background-color: #fbfbfb;
  &:hover > td {
    background-color: #fbfbfb;
  }
}

.ix-location-select-table.el-table td {
  padding: 0;
  border: 0;
}
.ix-location-select-table .ix-location-cell {
  display: flex;
  align-items: center;
  background-color: var(--color-white);
  border: 1px solid var(--card-border-color);
  border-radius: var(--border-radius-base);
  padding: 0.5rem;
  margin: 0.25rem 0;
}
.ix-location-cell {
  background-color: var(--color-white);
  word-break: normal;
  word-wrap: break-word;
  &:hover {
    border-color: var(--color-primary-light-5);
    background-color: var(--color-primary-light-9);
  }
  .ix-location-header {
    font-weight: 500;
  }
}

.current-row .ix-location-cell {
  .ix-location-header {
    color: var(--color-primary);
  }
  border-color: var(--color-primary);
  background-color: var(--color-primary-light-8);
  svg.icon {
    color: var(--color-primary);
    fill: currentColor;
  }
}
.ix-location-select-table .el-table__body tr.current-row > td {
  background-color: #fbfbfb;
}
</style>

