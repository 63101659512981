<template>
  <section class="term-group">
    <!-- Heading -->
    <div :id="labelId"
      class="font-weight-500 text-align-center full-width mb-1">
      {{ $t(isPartnerVantage ? 'partner-vantage.subscription' : 'ports.minimum-term') }}
      <el-tooltip placement="top"
        :open-delay="500"
        :content="helpTooltip">
        <i class="fas fa-question-circle color-info popover-info-icon"
          aria-hidden="true" />
      </el-tooltip>
    </div>

    <el-form-item :prop="prop"
      label-width="0">
      <div v-loading="showPricing && loadingTermPrices">
        <el-radio-group :value="value"
          name="minimumTerm"
          class="flex-column"
          :aria-labelledby="labelId"
          @input="$emit('input', $event)">
          <div v-for="option in termOptions"
            :key="option.term"
            class="d-flex">
            <el-radio-button :label="option.term"
              class="term-option"
              data-testid="term-option"
              :class="{ 'no-price': !showPricing }">
              <div class="segment label"
                data-testid="term-option-label">
                {{ minimumTermLabel(option) }}
              </div>
              <div v-if="showPricing"
                class="segment">
                <span class="rate">{{ $t('pricebook.monthly-rate') }}:</span>
                <span class="price"
                  data-testid="term-option-price">{{ minimumTermPrice(option) }}</span>
              </div>
            </el-radio-button>
          </div>
        </el-radio-group>
      </div>
      <slot />
    </el-form-item>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import { moneyFilter } from '@/helpers.js'

export default {
  inject: ['disabledFeatures', 'isFeatureEnabled'],

  props: {
    prop: {
      type: String,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
    termPrices: {
      type: Object,
      required: true,
    },
    loadingTermPrices: {
      type: Boolean,
      default: false,
    },
    minimumTerm: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['input'],

  computed: {
    ...mapGetters('Auth', ['isManagedAccount', 'isPartnerVantage']),

    helpTooltip() {
      // For a partner vantage partner or managed account, "term" is known as "subscription"
      if (this.isPartnerVantage) {
        return this.$t(this.minimumTerm ? 'partner-vantage.subscription-tooltip-ports' : 'partner-vantage.subscription-tooltip')
      }
      return this.$t(this.minimumTerm ? 'port-term.minimum-term-tooltip-ports' : 'port-term.minimum-term-tooltip')
    },
    labelId() {
      return `${this.prop}-label`
    },
    showPricing() {
      return !this.disabledFeatures.showPrices && this.isFeatureEnabled('PRICING_VISIBLE')
    },
    termOptions() {
      return Object.entries(this.termPrices)
        .map(([term, price]) => ({
          term: Number.parseInt(term),
          price,
        }))
        .sort((a, b) => a.term - b.term)
    },
  },

  methods: {
    minimumTermLabel({ term }) {
      return !this.minimumTerm && term === 1
        ? this.$t(this.isPartnerVantage ? 'partner-vantage.no-subscription' : 'port-term.no-minimum-term')
        : this.$tc('general.count-months', term)
    },
    minimumTermPrice({ price }) {
      return price ? moneyFilter(price.monthlyRate, price.currency) : '-'
    },
  },
}
</script>

<style lang="scss" scoped>
.term-group {
  border: 1px solid var(--border-color-base);
  border-radius: var(--border-radius-base);
  padding: 1rem 1.4rem 0 1rem;
  margin-bottom: 1rem;
  color: var(--color-text-primary);
}

.term-option {
  display: flex;
  flex: 1;
  max-width: 400px;
  margin: 0 auto;

  &.no-price {
    max-width: 300px;

    .label {
      text-align: center;
    }
  }

  ::v-deep(.el-radio-button__inner) {
    flex: 1;
    display: flex;
    flex-direction: row;

    :not(:last-child) > & {
      border-bottom: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    :not(:first-child) > & {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  .segment {
    flex: 1;
  }
  .label, .rate {
    text-align: left;
  }
  .rate {
    margin-right: 0.75rem;
    font-weight: lighter;
    font-size: small;
  }
  .price {
    text-align: right;
  }
}
</style>
