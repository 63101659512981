<template>
  <div>
    <h4 class="text-align-center mt-1">
      {{ $t('nutanix.configuration') }}
    </h4>

    <i18n path="nutanix.service-key-notice"
      tag="p">
      <template #link>
        <a href="https://www.nutanix.com/"
          target="_blank">{{ $t('nutanix.nutanix') }}</a>
      </template>
    </i18n>

    <el-form-item ref="serviceKeyInput"
      prop="serviceObj.bEnd.partnerConfig.serviceKey"
      :label="$t('nutanix.service-key')"
      label-position="top">
      <el-input v-model="key"
        v-loading="checking"
        type="text"
        placeholder="XXX-00000000-0000-0000-0000-000000000000"
        data-demo="b207f38a-8e73-4660-ac0c-ced67a4d009f"
        data-testid="nutanixKey"
        name="nutanixKey" />
    </el-form-item>

    <div class="text-align-center height-2-2rem">
      <div v-if="knownKey"
        class="color-success">
        <i class="fas fa-check-circle"
          aria-hidden="true" /> {{ $t('connections.valid-service-key') }}
      </div>
      <div v-else-if="checking"
        class="color-warning">
        {{ $t('connections.verifying-key') }}
      </div>
      <div v-else
        class="color-danger">
        {{ error }}
      </div>
    </div>

    <el-collapse-transition>
      <div v-if="knownKey">
        <el-form-item :label="$t('connections.nutanix-ports')"
          prop="serviceObj.bEnd.productUid">
          <div class="port-select-holder">
            <el-table ref="portSelectTable"
              :data="availableDestinations"
              highlight-current-row
              max-height="450"
              :show-header="true"
              :empty-text="message"
              row-class-name="port-row"
              class="port-select-table full-width collapsed-border hide-table-header"
              @row-click="handlePortSelect">
              <el-table-column property="port"
                label="Port"
                min-width="100">
                <template #default="{row}">
                  <div class="port-cell"
                    data-testid="port-cell"
                    :data-service-name="row.title"
                    :data-location="row._location && row._location.formatted && row._location.formatted.short"
                    :data-diversity-zone="row.diversityZone || 'none'">
                    <div class="height-2-5rem">
                      <mu-mega-icon icon="PORT"
                        class="icon" />
                    </div>
                    <div>
                      <div class="port-header">
                        {{ row.title }}
                      </div>
                      <div v-if="row._location && row._location.formatted">
                        {{ row._location.formatted.short }}
                      </div>
                    </div>
                    <div v-if="row.diversityZone"
                      class="ml-auto">
                      <i class="far fa-dot-circle diversity-icon"
                        :class="`diversity-color-${row.diversityZone}`"
                        :title="$t(`services.${row.diversityZone}-zone`)" />
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-form-item>
      </div>
    </el-collapse-transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import captureSentryError from '@/utils/CaptureSentryError.js'
import sdk from '@megaport/api-sdk'

export default {
  props: {
    aEnd: {
      type: Object,
      default: null,
    },
    bEnd: {
      type: Object,
      default() {
        return {}
      },
    },
    ports: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      productUid: null,
      key: null,
      checking: null,
      knownKey: null,
      error: null,
      countryFilter: '',
    }
  },
  computed: {
    ...mapGetters('Services', ['myPorts']),
    source() {
      if (!this.aEnd || !this.aEnd.productUid) return null
      return (
        this.myPorts.find(service => service.productUid === this.aEnd.productUid) || {
          aEnd: {},
          bEnd: {},
        }
      )
    },
    availableDestinations() {
      return this.ports
        .filter(port => {
          if (!this.knownKey || !this.knownKey.megaports) {
            return false
          }
          if (
            this.knownKey.megaports
              .filter(p => {
                return p.vxc ? false : true
              })
              .find(p => p.productUid === port.productUid)
          ) {
            return true
          }
          return false
        })
        .sort((a, b) => {
          if (a.title > b.title) return 1
          if (a.title < b.title) return -1
          return 0
        })
    },
    message() {
      if (!this.source || !this.source.locationId) return null
      if (this.knownKey && (this.availableDestinations.length === 0 || this.knownKey.megaports.length === 0)) {
        return this.$t('connections.no-available-ports-key')
      }
      return null
    },
  },
  watch: {
    productUid() {
      this.updatePartnerConfig()
    },
    key() {
      this.key = this.key.trim()
      this.checkKey()
    },
  },
  mounted() {
    this.$inputField = this.$refs['text-input']
  },
  created() {
    if (this.bEnd.partnerConfig && this.bEnd.partnerConfig.serviceKey) {
      this.key = this.bEnd.partnerConfig.serviceKey
    }

    this.productUid = this.bEnd.productUid
  },
  methods: {
    updatePartnerConfig() {
      const config = {
        productUid: this.productUid,
        vlan: this.knownKey ? this.knownKey.vlan : null,
        partnerConfig: {
          connectType: 'NUTANIX',
          serviceKey: this.key,
          validKey: this.knownKey !== null,
          fixedPortSpeed: this.knownKey ? this.knownKey.bandwidth : null,
        },
      }
      this.$emit('update', config)
      this.$refs.serviceKeyInput?.elForm.validateField('serviceObj.bEnd.partnerConfig.serviceKey')
    },

    handlePortSelect(val) {
      // If nothing was selected or the same item as last time was clicked, then deselect.
      if (!val || (this.productUid && this.productUid === val.productUid)) {
        this.$refs.portSelectTable.setCurrentRow()
        this.productUid = null
      } else {
        this.productUid = val.productUid
      }
    },

    checkKey() {
      const tmpUid = this.productUid
      this.productUid = null
      this.knownKey = null
      this.error = null

      if (this.key.length === 40 || this.key.length === 36) {
        this.checking = true
        sdk.instance
          .product()
          .nutanix(this.key)
          .then(gData => {
            this.checking = false
            this.knownKey = gData
            if (gData.megaports && tmpUid) {
              const isEndProductUid = gData.megaports.find(port => tmpUid === port.productUid)
              if (isEndProductUid) {
                this.productUid = tmpUid
              }
            }
            this.error = null

            this.$nextTick(() => {
              // See if the selected port was in the list, and if so, select it.
              if (this.knownKey.megaports && this.productUid && this.$refs.portSelectTable) {
                const index = this.availableDestinations.findIndex(port => {
                  return port.productUid === this.productUid
                })
                if (index !== -1) {
                  // Set the current row
                  this.$refs.portSelectTable.setCurrentRow(this.availableDestinations[index])
                  // Scroll the selected row to visible - nasty hacky way to do it, but it works
                  const targetTop = this.$refs.portSelectTable.$el.querySelectorAll('.el-table__body tr')[index].getBoundingClientRect().top
                  const containerTop = this.$refs.portSelectTable.$el.querySelector('.el-table__body').getBoundingClientRect().top
                  const scrollParent = this.$refs.portSelectTable.$el.querySelector('.el-table__body-wrapper')
                  scrollParent.scrollTop = targetTop - containerTop
                } else {
                  this.productUid = null
                }
              }
            })

            this.updatePartnerConfig()
          })
          .catch(e => {
            this.knownKey = null
            this.checking = false
            this.error = this.$t('validations.invalid-service-key')
            this.updatePartnerConfig()
            // Expect a 400 error from a bad key - anything else should be
            // reported to the sentry gods.
            if (e.status !== 400) {
              captureSentryError(e)
            }
          })
      } else {
        this.updatePartnerConfig()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.port-select-holder {
  background-color: #fbfbfb;
  border: 1px solid var(--card-border-color);
  border-radius: var(--border-radius-base);
  padding: 0.5rem;
  margin-top: 1rem;
  clear: both;
}
.is-error .port-select-holder {
  border-color: var(--color-danger);
}
.is-success .port-select-holder {
  border-color: var(--color-success);
}
svg.icon {
  display: inline-block;
  color: var(--color-text-regular);
  width: 2.5rem;
  height: 2.5rem;
  fill: currentColor;
}
.port-cell svg {
  margin-right: 0.5rem;
}
</style>
<style lang="scss">
.port-select-table table tr.port-row {
  background-color: #fbfbfb;
  &:hover > td {
    background-color: #fbfbfb;
  }
}
.port-select-table.el-table td {
  padding: 0;
  border: 0;
}
.port-select-table .port-cell {
  display: flex;
  align-items: center;
  background-color: var(--color-white);
  border: 1px solid var(--card-border-color);
  border-radius: var(--border-radius-base);
  padding: 0.5rem;
  margin: 0.25rem 0;
}
.port-cell {
  background-color: var(--color-white);
  word-break: normal;
  word-wrap: break-word;
  &:hover {
    border-color: var(--color-primary-light-5);
    background-color: var(--color-primary-light-9);
  }
  .port-header {
    font-weight: 700;
  }
}
.current-row .port-cell {
  .port-header {
    color: var(--color-primary);
  }
  border-color: var(--color-primary);
  background-color: var(--color-primary-light-8);
  svg.icon {
    color: var(--color-primary);
    fill: currentColor;
  }
}
.port-select-table .el-table__body tr.current-row > td {
  background-color: #fbfbfb;
}
</style>
