<template>
  <div>
    <h4 class="text-align-center mt-1">
      {{ gtype === 'GOOGLE' ? $t('google.cloud-configuration') : $t('google.gci-configuration') }}
    </h4>

    <template v-if="!showAllPorts">
      <template v-if="gtype === 'GOOGLE'">
        <el-form-item ref="serviceKeyInput"
          prop="serviceObj.bEnd.partnerConfig.pairingKey"
          :label="$t('google.partner-peering-key')"
          label-position="top">
          <el-input v-model="key"
            v-loading="checking"
            type="text"
            placeholder="00000000-0000-0000-0000-000000000000/region/0"
            data-demo="7e51371e-72a3-40b5-b844-2e3efefaee59/us-central1/2"
            data-testid="googleKey"
            name="googleKey" />
        </el-form-item>

        <el-collapse-transition>
          <div v-if="!knownKey">
            <p>
              {{ $t('connections.google-key-format') }}<br>
              &lt;random&gt;/&lt;vlan-attachment-region&gt;/&lt;edge-availability-domain&gt;<br>
              {{ $t('general.example-abbr') }} <span>7e51371e-72a3-40b5-b844-2e3efefaee59/us-central1/2</span>
            </p>
          </div>
        </el-collapse-transition>

        <div class="text-align-center key-result-display">
          <div v-if="knownKey"
            class="color-success"
            data-name="googleKeySuccess">
            <i class="fas fa-check-circle"
              aria-hidden="true" /> {{ $t('connections.valid-service-key') }}
          </div>
          <div v-else-if="checking"
            class="color-warning">
            {{ $t('connections.verifying-key') }}
          </div>
          <div v-else
            class="color-danger"
            data-name="googleKeyFail">
            {{ error }}
          </div>
        </div>
      </template>

      <el-collapse-transition>
        <div v-if="knownKey">
          <el-form-item :label="$t('connections.google-ports')"
            prop="serviceObj.bEnd.productUid">
            <div class="port-select-holder">
              <el-table ref="portSelectTable"
                :data="availableDestinations"
                highlight-current-row
                max-height="450"
                :show-header="true"
                :empty-text="message"
                row-class-name="port-row"
                class="port-select-table full-width collapsed-border hide-table-header"
                @row-click="handlePortSelect">
                <el-table-column property="port"
                  :label="$t('productNames.port')"
                  min-width="100">
                  <template #default="{row}">
                    <div class="port-cell"
                      data-testid="port-cell"
                      :data-service-name="row.title"
                      :data-location="row._location && row._location.formatted && row._location.formatted.short"
                      :data-diversity-zone="row.diversityZone || 'none'">
                      <div class="height-2-5rem">
                        <mu-mega-icon :icon="row.virtual ? 'MCR' : 'PORT'"
                          class="icon" />
                      </div>
                      <div>
                        <div class="port-header">
                          {{ row.title }}
                        </div>
                        <div v-if="row._location && row._location.formatted">
                          {{ row._location.formatted.short }}
                        </div>
                      </div>
                      <div v-if="row.diversityZone"
                        class="ml-auto">
                        <i class="far fa-dot-circle diversity-icon"
                          :class="`diversity-color-${row.diversityZone}`"
                          :title="$t(`services.${row.diversityZone}-zone`)" />
                      </div>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-form-item>
        </div>
      </el-collapse-transition>

      <el-collapse-transition>
        <div v-if="availableDestinations.length === 0"
          class="text-align-center mt-1 text-underline">
          <a class="cursor-pointer"
            @click="updateShowAllPorts(true)">{{ $t('google.show-all-locations') }}</a>
        </div>
      </el-collapse-transition>
    </template>
    <template v-if="showAllPorts">
      <el-form-item :label="$t('connections.select-destination-port-title')"
        prop="serviceObj.bEnd.productUid">
        <el-select v-model="countryFilter"
          clearable
          class="dont-color full-width"
          :placeholder="$t('general.country-filter')"
          name="countryFilter">
          <el-option v-for="country in countries"
            :key="country"
            :value="country">
            {{ country }}
          </el-option>
        </el-select>

        <div class="flex-row-centered zone-table-header">
          <div>{{ $t('services.diversity-zone') }}:</div>
          <div>
            <el-radio-group v-model="zoneFilter"
              size="mini">
              <el-radio-button :label="null">
                {{ $t('general.all') }}
              </el-radio-button>
              <el-radio-button :label="G_DIVERSITY_ZONE_RED"
                class="dz-dot">
                <i class="far fa-dot-circle diversity-icon diversity-color-red"
                  :title="$t(`services.red-zone`)" />
              </el-radio-button>
              <el-radio-button :label="G_DIVERSITY_ZONE_BLUE"
                class="dz-dot">
                <i class="far fa-dot-circle diversity-icon diversity-color-blue"
                  :title="$t(`services.blue-zone`)" />
              </el-radio-button>
            </el-radio-group>
          </div>
        </div>

        <div class="port-select-holder">
          <el-table ref="portSelectTable"
            :data="allPorts"
            highlight-current-row
            max-height="340"
            :show-header="true"
            :empty-text="$t('connections.no-available-target-ports')"
            row-class-name="port-row"
            class="port-select-table full-width collapsed-border hide-table-header"
            @row-click="handlePortSelect">
            <el-table-column property="port"
              :label="$t('productNames.port')"
              min-width="100">
              <template #default="{row}">
                <div class="port-cell"
                  data-testid="port-cell"
                  :data-service-name="row.title"
                  :data-location="row._location && row._location.formatted && row._location.formatted.short"
                  :data-diversity-zone="row.diversityZone || 'none'">
                  <div class="height-2-5rem">
                    <mu-mega-icon :icon="row.virtual ? 'MCR' : 'PORT'"
                      class="icon" />
                  </div>
                  <div>
                    <div class="port-header">
                      {{ row.title }}
                    </div>
                    <div v-if="row._location">
                      {{ row._location.formatted.short }}
                    </div>
                  </div>
                  <div v-if="row.diversityZone"
                    class="ml-auto">
                    <i class="far fa-dot-circle diversity-icon"
                      :class="`diversity-color-${row.diversityZone}`"
                      :title="$t(`services.${row.diversityZone}-zone`)" />
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-form-item>
      <div class="text-align-center mt-1 text-underline cursor-pointer">
        <a @click="updateShowAllPorts(false)">{{ $t('google.back-to-enter-peering') }}</a>
      </div>
    </template>
  </div>
</template>

<script>
// External tools
import { mapGetters } from 'vuex'
import sdk from '@megaport/api-sdk'
// Internal tools
import captureSentryError from '@/utils/CaptureSentryError.js'

export default {
  props: {
    aEnd: {
      type: Object,
      required: true,
    },
    bEnd: {
      type: Object,
      required: true,
    },
    ports: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      gtype: 'GOOGLE',
      productUid: null,
      key: null,
      checking: null,
      knownKey: null,
      error: null,
      showAllPorts: false,
      countryFilter: '',
      zoneFilter: null,
    }
  },

  computed: {
    ...mapGetters('Services', ['myPorts']),
    source() {
      if (!this.aEnd || !this.aEnd.productUid) return null
      return (
        this.myPorts.find(service => service.productUid === this.aEnd.productUid) || {
          aEnd: {},
          bEnd: {},
        }
      )
    },
    availableDestinations() {
      return this.ports
        .filter(port => {
          if (this.gtype === 'GCI' && port.connectType === 'GCI') {
            return this.originMarkets.indexOf(port._location.market) > -1
          }

          if (!this.knownKey || !this.knownKey.megaports) {
            return false
          }
          if (
            this.knownKey.megaports
              .filter(p => {
                return p.vxc ? false : true
              })
              .find(p => p.productUid === port.productUid)
          ) {
            return true
          }
          return false
        })
        .sort((a, b) => {
          if (a.title > b.title) return 1
          if (a.title < b.title) return -1
          return 0
        })
    },
    originMarkets() {
      if (!this.source || !this.source.locationId || !this.source._location) return []
      const market = this.source._location.market

      let markets = []
      switch (market) {
        case 'AU':
        case 'NZ':
          break // ANZ are not available for GCI connections
        case 'US':
        case 'CA':
          markets = ['US', 'CA'] // North America
          break
        case 'HK':
          markets = ['HK'] // Hong Kong only to Hong Kong
          break
        case 'SG':
          markets = ['SG'] // Singapore only to Singaport
          break
        default:
          markets = ['BG', 'DE', 'UK', 'NL', 'IE', 'SE'] // Europe
      }
      return markets
    },
    message() {
      if (!this.source || !this.source.locationId) return null
      if (this.originMarkets.length === 0 && this.gtype === 'GCI') {
        return this.$t('connections.no-google-services')
      }
      if (this.knownKey && (this.availableDestinations.length === 0 || this.knownKey.megaports.length === 0)) {
        return this.$t('connections.no-google-ports')
      }
      // if (this.availableDestinations.length === 0) return 'Enter a valid pairing key to view a list of available ports.';
      return null
    },
    allPorts() {
      return this.$store.getters['Services/targetPorts']
        .filter(p => p.connectType === 'GOOGLE' && p.vxcPermitted === true)
        .filter(port => {
          if (this.countryFilter && this.countryFilter !== port._location.country) return false
          if (this.zoneFilter && this.zoneFilter !== port.diversityZone) return false
          return true
        })
        .sort((a, b) => {
          if (a.title > b.title) return 1
          if (a.title < b.title) return -1
          return 0
        })
    },
    countries() {
      if (this.$store.getters['Services/targetPorts'].filter(p => p.connectType === 'GOOGLE')) {
        return [...new Set(this.$store.getters['Services/targetPorts'].filter(p => p.connectType === 'GOOGLE').map(p => p._location?.country))].sort()
      }
      return []
    },
  },

  watch: {
    productUid() {
      this.emitUpdate()
    },
    key() {
      if (this.key === null) {
        return true
      }
      this.key = this.key.trim()
      this.checkKey()
    },
  },

  created() {
    if (this.bEnd.partnerConfig && this.bEnd.partnerConfig.connectType && ['GOOGLE', 'GCI'].includes(this.bEnd.partnerConfig.connectType)) {
      this.gtype = this.bEnd.partnerConfig.connectType
    }

    if (this.bEnd.partnerConfig && this.bEnd.partnerConfig.pairingKey) {
      this.key = this.bEnd.partnerConfig.pairingKey
    }

    this.productUid = this.bEnd.productUid
  },

  updated() {
    if (this.$refs.portSelectTable) this.showSelectedPort()
  },

  methods: {
    showSelectedPort() {
      // If the destination is already selected, try to show it in the port selection table
      if ((this.productUid && this.availableDestinations) || this.$refs.portSelectTable) {
        const index = this.availableDestinations.findIndex(destination => {
          return destination.productUid === this.productUid
        })

        if (index !== -1) {
          // Set the current row
          this.$refs.portSelectTable.setCurrentRow(this.availableDestinations[index])

          // Scroll the selected row to visible - nasty hacky way to do it, but it works
          const targetTop = this.$refs.portSelectTable.$el.querySelectorAll('.el-table__body tr')[index].getBoundingClientRect().top
          const containerTop = this.$refs.portSelectTable.$el.querySelector('.el-table__body').getBoundingClientRect().top
          const scrollParent = this.$refs.portSelectTable.$el.querySelector('.el-table__body-wrapper')
          scrollParent.scrollTop = targetTop - containerTop
        }
      }
    },
    emitUpdate() {
      if (this.gtype === 'GCI') {
        this.$emit('update', {
          productUid: this.productUid,
          partnerConfig: {
            connectType: this.gtype,
            validKey: this.knownKey !== null,
          },
        })
      } else if (this.gtype === 'GOOGLE') {
        this.$emit('update', {
          productUid: this.productUid,
          partnerConfig: {
            connectType: this.gtype,
            pairingKey: this.key,
            validKey: this.knownKey !== null,
            fixedBandwidths: this.knownKey ? this.knownKey.bandwidths : undefined,
          },
        })
      }
      this.$refs.serviceKeyInput?.elForm.validateField('serviceObj.bEnd.partnerConfig.pairingKey')
    },
    handlePortSelect(val) {
      // If nothing was selected or the same item as last time was clicked, then deselect.
      if (!val || (this.productUid && this.productUid === val.productUid)) {
        this.$refs.portSelectTable.setCurrentRow()
        this.productUid = null
      } else {
        this.productUid = val.productUid
      }
    },
    updateShowAllPorts(tf) {
      this.showAllPorts = tf
      if (tf === true) {
        this.key = null
        this.emitUpdate()
      }
    },
    checkKey() {
      const tmpUid = this.productUid
      this.productUid = null
      this.knownKey = null

      if (this.key.match(/^[A-Za-z0-9]{8}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{12}\/[A-Za-z0-9-]+\/[A-Za-z0-9-]+$/)) {
        this.checking = true
        sdk.instance
          .product()
          .google(this.key)
          .then(gData => {
            this.checking = false
            this.knownKey = gData
            if (gData.megaports && tmpUid) {
              const isEndProductUid = gData.megaports.find(port => tmpUid === port.productUid)
              if (isEndProductUid) {
                this.productUid = tmpUid
              }
            }
            this.error = null

            this.emitUpdate()
          })
          .catch(e => {
            this.knownKey = null
            this.checking = false
            this.error = this.$t('validations.pairing-key-invalid')
            this.emitUpdate()
            // It should always return a 400 error for an invalid key
            if (e.status !== 400) {
              captureSentryError(e)
            }
          })
      } else {
        this.emitUpdate()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.key-result-display {
  height: 2.2rem;
}
.text-underline {
  text-decoration: underline;
}
.port-select-holder {
  background-color: #fbfbfb;
  border: 1px solid var(--card-border-color);
  border-radius: var(--border-radius-base);
  padding: 0.5rem;
  margin-top: 1rem;
  clear: both;
}
.is-error .port-select-holder {
  border-color: var(--color-danger);
}
.is-success .port-select-holder {
  border-color: var(--color-success);
}
svg.icon {
  display: inline-block;
  color: var(--color-text-regular);
  width: 2.5rem;
  height: 2.5rem;
  fill: currentColor;
}
.port-cell svg {
  margin-right: 0.5rem;
}
</style>

<style lang="scss">
.port-select-table table tr.port-row {
  background-color: #fbfbfb;
  &:hover > td {
    background-color: #fbfbfb;
  }
}
.port-select-table.el-table td {
  padding: 0;
  border: 0;
}
.port-select-table .port-cell {
  display: flex;
  align-items: center;
  background-color: var(--color-white);
  border: 1px solid var(--card-border-color);
  border-radius: var(--border-radius-base);
  padding: 0.5rem;
  margin: 0.25rem 0;
}
.port-cell {
  background-color: var(--color-white);
  word-break: normal;
  word-wrap: break-word;
  &:hover {
    border-color: var(--color-primary-light-5);
    background-color: var(--color-primary-light-9);
  }
  .port-header {
    font-weight: 700;
  }
}
.current-row .port-cell {
  .port-header {
    color: var(--color-primary);
  }
  border-color: var(--color-primary);
  background-color: var(--color-primary-light-8);
  svg.icon {
    color: var(--color-primary);
    fill: currentColor;
  }
}
.port-select-table .el-table__body tr.current-row > td {
  background-color: #fbfbfb;
}
</style>
