// This object handles validations for the different parts of the connection creation and editing
// process so that you can have basically essentially self contained components for each of the
// screens, while still having the top level component able to pass in data to be validated so that
// validation becomes available both at the top level and within the screen.
import AsyncValidator from 'async-validator'

class ConnectionValidator {
  constructor(vueComponent) {
    // The validations need to have a vue component on which to base the translations
    // for the error messages. Note that the translations are done once at setup and the
    // value is stored in the instance variable, so we don't need to reference the component
    // locally.

    const validateMpServiceKey = (rule, value, callback) => {
      if (this.supportingData['target-select-key']?.errorMessage) {
        callback(this.supportingData['target-select-key'].errorMessage)
      } else if (!this.supportingData['target-select-key']?.productUid) {
        callback(vueComponent.$t('validations.key-invalid'))
      } else {
        callback()
      }
    }

    // Have each validation section under a different key in the validations.
    this.validations = {
      'target-select-key': {
        key: [
          {
            required: true,
            message: vueComponent.$t('validations.required', { thing: 'Service key' }),
            trigger: 'blur',
          },
          {
            pattern: '^[a-zA-Z0-9]{8}$|^[a-zA-Z0-9\\-]{36}$',
            message: vueComponent.$t('validations.service-key-invalid'),
            trigger: 'change',
          },
          {
            validator: validateMpServiceKey,
          },
        ],
      },
      'target-select-ix': {
        ixType: [
          {
            type: 'object',
            required: true,
            message: vueComponent.$t('validations.ix-selection-required'),
            trigger: 'change',
          },
        ],
      },
      'configure-aend': {
        thing: [
          {
            required: true,
          },
          {
            min: 5,
            message: vueComponent.$t('ports.percent-allocated', {
              percent: 42,
            }),
          },
        ],
        other: [
          {
            required: true,
          },
          {
            max: 1,
          },
        ],
      },
    }
  }

  /**
   * Use this to supply any data that is required during validation but is not directly available
   * from the value being assessed. The convention is to use the same key as for the validation itself
   */
  supportingData = {}

  setSupportingData(key, data) {
    this.supportingData[key] = data
  }

  // Use this for getting the validation rules for a particular section in the UI
  validationForType(type) {
    return this.validations[type]
  }

  // Use this to validate the data outside the scope of the user interface
  validate(type, data) {
    const originalDescriptor = this.validationForType(type)

    return new Promise((resolve, reject) => {
      if (!originalDescriptor) {
        reject({
          type,
          errors: [
            {
              message: 'No such rule set',
            },
          ],
        })
        return
      }
      // The validator sometimes gets confused by having the trigger included in the
      // descriptor, so remove it
      const descriptor = {
        ...originalDescriptor,
      }
      Object.values(descriptor).forEach(value => {
        delete value.trigger
      })
      const validator = new AsyncValidator(descriptor)
      validator.validate(
        data,
        {
          firstFields: true,
        },
        errors => {
          if (errors && errors.length) {
            reject({
              type,
              errors,
            })
          } else {
            resolve(type)
          }
        }
      )
    })
  }
}

export default ConnectionValidator
