<template>
  <div class="content">
    <h4 class="text-align-center mt-1 mb-2">
      {{ $t('ibm.connection-details') }}
    </h4>

    <!-- Account ID -->
    <el-form-item prop="serviceObj.bEnd.partnerConfig.account_id"
      :label-width="labelWidth">
      <template #label>
        {{ $t('ibm.account-id') }}
        <el-tooltip placement="top"
          :content="$t('ibm.account-id-tooltip')"
          :open-delay="500">
          <i class="fas fa-question-circle color-info popover-info-icon"
            aria-hidden="true" />
        </el-tooltip>
      </template>
      <el-input v-model="config.account_id"
        type="text"
        data-demo="e8dcb8cd31a52eac3774fb791dac8fcf"
        data-testid="accountId" />
    </el-form-item>

    <!-- Customer ASN -->
    <el-form-item v-if="!isMcr"
      prop="serviceObj.bEnd.partnerConfig.customer_asn"
      class="long-error"
      :label-width="labelWidth">
      <template #label>
        {{ $t('connections.customer-asn') }}
        <el-tooltip placement="top"
          :content="$t('ibm.customer-asn-tooltip')"
          :open-delay="500">
          <i class="fas fa-question-circle color-info popover-info-icon"
            aria-hidden="true" />
        </el-tooltip>
      </template>
      <el-input v-model.number="config.customer_asn"
        type="text"
        data-demo="64999"
        data-testid="customerAsn" />
    </el-form-item>

    <!-- Customer IP Address -->
    <el-form-item prop="serviceObj.bEnd.partnerConfig.customer_ip_address"
      :label="$t('connections.customer-ip')"
      :label-width="labelWidth">
      <el-input v-model="config.customer_ip_address"
        type="text"
        placeholder="e.g. 192.0.1.0/30"
        data-demo="172.0.0.1/30"
        data-testid="customerIpAddress" />
    </el-form-item>

    <!-- Provider IP Address -->
    <el-form-item prop="serviceObj.bEnd.partnerConfig.provider_ip_address"
      :label="$t('connections.provider-ip')"
      :label-width="labelWidth">
      <el-input v-model="config.provider_ip_address"
        type="text"
        placeholder="e.g. 192.0.1.0/30"
        data-demo="172.0.0.1/30"
        data-testid="providerIpAddress" />
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: 'IbmConfig',

  props: {
    value: {
      type: Object,
      required: true,
    },
    aEndConnection: {
      type: Object,
      required: false,
      default: undefined,
    },
  },

  emits: ['input'],

  data() {
    return {
      vlan: 0,
      labelWidth: '200px',
      // Initalize IBM config form fields
      config: {
        account_id: null,
        customer_asn: null,
        customer_ip_address: null,
        provider_ip_address: null,
      },
    }
  },

  computed: {
    /**
     * Determine if form is complete
     */
    isComplete() {
      return !!(this.config.account_id && (this.isMcr || this.config.customer_asn))
    },
    /**
     * Check if we're on an MCR to disable ASN field
     */
    isMcr() {
      return this.aEndConnection?.productType === this.G_PRODUCT_TYPE_MCR2
    },
  },

  watch: {
    config: {
      handler() {
        // Emit every time config object has changed
        this.emitUpdatedPartnerConfig()
      },
      deep: true,
    },
  },

  created() {
    if (this.value.vlan) this.vlan = this.value.vlan
    if (this.value.partnerConfig) {
      // This strips out all the keys we are not interested in here and only picks the ones we are interested in
      // for this editing screen. Since this is emitted on the v-model, it automatically strips out the "isLive"
      // key, which means that we can pick that up before submitting.
      Object.keys(this.value.partnerConfig).forEach(key => {
        if (typeof this.config[key] !== 'undefined' && key !== 'complete') {
          this.config[key] = this.value.partnerConfig[key]
        }
      })
      this.emitUpdatedPartnerConfig()
    }
  },

  methods: {
    /**
     * Emit partnerConfig details back to CreateConnection
     */
    emitUpdatedPartnerConfig() {
      this.$emit('input', {
        vlan: this.vlan,
        productUid: this.value.productUid,
        partnerConfig: Object.assign({}, this.config, {
          connectType: 'IBM',
          complete: this.isComplete,
          fixedBandwidths: this.value.partnerConfig.fixedBandwidths,
        }),
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.content {
  min-width: 500px;
}
</style>
