<template>
  <el-form-item :prop="prop"
    class="long-error"
    :label-width="labelWidth">
    <template #label>
      {{ label }}
      <el-tooltip v-if="helpText"
        placement="top"
        :content="helpText"
        :open-delay="500">
        <i class="fas fa-question-circle color-info popover-info-icon"
          aria-hidden="true" />
      </el-tooltip>
    </template>

    <div class="flex-row-centered">
      <template v-if="fixedBandwidths">
        <el-select ref="text-input"
          v-model.number="rateLimit"
          data-testid="rate-limit-select"
          name="rateLimit"
          :placeholder="$t('general.please-select')"
          :disabled="disabled"
          class="min-width-240px">
          <el-option v-for="speed in filteredBandwidths"
            :key="speed"
            :value="speed"
            :data-speed="speed"
            :label="speedFix(speed)" />
        </el-select>
      </template>
      <template v-if="fixedRate">
        <el-input ref="text-input"
          v-model.number="rateLimit"
          type="number"
          name="rateLimit"
          data-testid="rate-limit-input"
          disabled
          :data-demo="fixedRate" />
      </template>
      <template v-else-if="!fixedBandwidths">
        <el-input ref="text-input"
          v-model.number="rateLimit"
          type="number"
          data-testid="rate-limit-input"
          name="rateLimit"
          autocomplete="off"
          :min="minRateLimit"
          :max="maxRateLimit"
          :disabled="disabled"
          :data-demo="maxRateLimit || 500"
          :data-max-rate-limit="maxRateLimit"
          class="min-width-240px" />
      </template>
      <div v-if="tipText"
        class="hide-on-disabled px-1 white-space-nowrap"
        :class="{ 'min-width-130px': showHigherSpeedUrl }">
        <p class="m-0">
          {{ tipText }}
        </p>
        <a v-if="showHigherSpeedUrl" 
          :href="higherSpeedUrl"
          target="_blank"
          class="request-form-link">
          {{ $t('connections.request-higher-speed') }}
        </a>
      </div>
    </div>

    <div v-if="fixedSpeedService">
      <p class="m-0">
        {{ $t('services.rate-limit-fixed') }}
      </p>
    </div>
  </el-form-item>
</template>

<script>
import { debounce } from 'lodash'
import { convertSpeed } from '@/helpers.js'

export default {
  name: 'InputRateLimit',
  props: {
    prop: {
      type: String,
      required: false,
      default: '',
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    labelWidth: {
      type: String,
      required: false,
      default: null,
    },
    minRateLimit: {
      type: Number,
      required: false,
      default: 1,
    },
    maxRateLimit: {
      type: Number,
      required: false,
      default: null,
    },
    fixedBandwidths: {
      type: [Array, Boolean],
      required: false,
      default: null,
    },
    fixedSpeedService: {
      type: Boolean,
      required: false,
      default: false,
    },
    value: {
      type: Number,
      required: false,
      default: null,
    },
    fixedRate: {
      type: Boolean,
      required: false,
      default: false,
    },
    helpText: {
      type: String,
      required: false,
      default: null,
    },
    higherSpeedUrl: {
      type: String,
      required: false,
      default: null,
    },
    provisioningStatus: {
      type: String,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      rateLimit: this.value,
    }
  },

  computed: {
    filteredBandwidths() {
      return this.fixedBandwidths.filter(bw => bw <= this.maxRateLimit)
    },
    tipText() {
      if (this.fixedBandwidths) {
        return null
      }
      if (this.fixedRate) {
        return this.$t('services.fixed-rate')
      }

      return this.$t('general.max-rate-limit', { maxRateLimit: this.maxRateLimit })
    },
    invalid() {
      if (this.fixedBandwidths && !this.rateLimit) {
        return this.$t('validations.required', { thing: this.$t('services.rate-limit') })
      }
      if (!this.rateLimit) {
        return this.$t('validations.mbps-value-range', { min: this.minRateLimit, max: this.maxRateLimit })
      }
      if (!/^\d+$/.test(this.rateLimit)) {
        return this.$t('validations.value-integer')
      }
      if (!this.fixedRate) {
        if (this.rateLimit < this.minRateLimit) {
          return this.$t('validations.ge', { value: this.minRateLimit })
        }
        if (this.maxRateLimit < this.rateLimit) {
          return this.$t('validations.value-too-high', { max: this.maxRateLimit })
        }
      }
      return false
    },
    showHigherSpeedUrl() {
      return this.higherSpeedUrl && !this.fixedRate
    },
  },

  watch: {
    rateLimit(newRate) {
      if (!this.fixedBandwidths && !this.fixedRate) {
        // We use a debounce to reduce the number of API calls made while the user is typing
        this.debouncedEmitInput(newRate)
        // Immediately skip the debounce for null updates since they don't trigger an API call
        if (!newRate) this.debouncedEmitInput.flush()
      } else {
        // Don't use a debounce with fixedBandwidths since it's a select list
        // Nor fixed rate since the user doesn't get control of the input
        this.emitInput(newRate)
      }
    },
    value(newValue) {
      this.rateLimit = newValue
    },
  },

  mounted() {
    this.rateLimit = (this.value <= this.maxRateLimit) || (this.provisioningStatus === this.G_PROVISIONING_LIVE) ? this.value : null
    this.$inputField = this.$refs['text-input']
    this.debouncedEmitInput = debounce(this.emitInput, 300)
  },

  methods: {
    emitInput(newRate) {
      this.$emit('input', newRate || null)
    },
    speedFix(val) {
      return convertSpeed(val)
    },
  },
}
</script>

<style lang="scss" scoped>
.min-width-130px {
  min-width: 130px;
}

.min-width-240px {
  min-width: 240px;
}

.request-form-link {
  position: absolute;
  top: 0;
  margin: 0;
  padding-top: 2rem;
  font-size: 13px;
}
</style>

<style lang="scss">
.is-disabled + .hide-on-disabled {
  color: transparent;
}
</style>
