<template>
  <el-card v-loading="loading"
    shadow="never"
    class="details-card"
    :class="{ active, disabled }">
    <mu-mega-icon v-if="leftIconId"
      :icon="leftIconId"
      class="icon" />
    <div class="details-lines">
      <div v-for="(line, index) in detailLines"
        :key="`plain-${index}`">
        {{ line }}
      </div>
      <div v-if="diversityZone">
        <span :title="$t(`services.${diversityZone}-zone`)"
          class="diversity-container">
          {{ $t('services.diversity-zone') }}
          <img v-if="diversityZone === G_DIVERSITY_ZONE_AUTO"
            src="@/assets/images/auto-diversity.svg"
            :alt="$t('images.auto-diversity')"
            width="12px"
            height="12px"
            class="diversity-zone-icon"
            aria-hidden="true">
          <i v-else
            class="far fa-dot-circle diversity-zone-icon"
            :class="`diversity-color-${diversityZone}`"
            aria-hidden="true" />
          <span class="sr-only">{{ $t(`general.${diversityZone}`) }}</span>
        </span>
      </div>
      <slot name="extraContent" />
    </div>
    <div v-if="rightImageSrc"
      class="ml-auto">
      <img :src="rightImageSrc"
        :alt="$t('images.datacentre-logo')"
        class="datacentre-logo ml-1"
        @error="setFallbackImage($event, 'https://media.megaport.com/datacentre/fallback/MissingDC.svg')">
    </div>
  </el-card>
</template>

<script>
import { setFallbackImage } from '@/utils/fallbackImage'

export default {
  name: 'EndDetailsCard',

  props: {
    /**
     * Will display a loading spinner
     */
    loading: {
      type: Boolean,
      default: false,
    },
    /**
     * Will display the content highlighted
     */
    active: {
      type: Boolean,
      default: false,
    },
    /**
     * Will display the content grayed out
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * The icon mega-icon name to use.
     */
    leftIconId: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * The detail lines to display on the centre of the panel. Plain text. First
     * line will be darker colour. If you want any additional content, including
     * formatted HTML content, use the extraContent slot. Note that the content for
     * the extraContent slot needs to have each line of the content within a div in
     * order for the formatting to be applied.
     */
    detailLines: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },
    /**
     * Which diversity zone to display, if any
     */
    diversityZone: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * Image to display at the right hand side.
     */
    rightImageSrc: {
      type: String,
      required: false,
      default: null,
    },
  },

  methods: {
    setFallbackImage,
  },
}
</script>

<style lang="scss" scoped>
.details-card {
  border: 1px solid var(--card-border-color);
  border-radius: var(--border-radius-base);
  outline: 0.25rem solid transparent;
  word-break: break-all;
  min-width: 350px;
  margin: 2rem;
  line-height: 0;
  &.active {
    outline-color: var(--color-primary);
  }
  &.disabled {
    color: var(--font-color-disabled-base);
    border-color: var(--disabled-border-base);
  }
}
svg.icon {
  display: inline-block;
  color: var(--color-text-regular);
  width: 2.5em;
  height: 2.5em;
  fill: currentColor;
}
.disabled {
  svg.icon {
    color: var(--font-color-disabled-base);
  }
  img {
    opacity: 0.35;
  }
}

.diversity-container {
  display: flex;
  gap: 0.3rem;
  align-items: center;
}

.diversity-zone-icon {
  margin-right: 0.25rem;
  font-size: 1.2rem;
}
</style>

<style lang="scss">
.details-card .el-card__body {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.details-card .details-lines {
  font-size: 1.4rem;
  display: flex;
  flex-direction: column;
  line-height: normal;
  justify-content: center;
  margin-left: 1rem;
  height: 5.7rem;
  color: var(--color-text-primary);
  font-weight: 500;
  div:not(:first-child) {
    font-weight: 400;
    font-size: 1.2rem;
    color: var(--color-text-regular);
  }
}
.details-card.disabled .details-lines {
  color: var(--font-color-disabled-base);
  border-color: var(--disabled-border-base);
}
</style>
