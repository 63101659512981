<template>
  <div id="azureKey">
    <h4 class="text-align-center mt-1">
      {{ $t('azure.configuration') }}
    </h4>

    <div class="text-align-center mt-1-5">
      <el-form-item prop="azureShowAllPorts">
        <el-radio-group v-model="showAllPorts"
          name="showAllPorts">
          <el-radio-button :label="false">
            {{ $t('services.service-key') }}
          </el-radio-button>
          <el-radio-button :label="true">
            {{ $t('general.pricing') }}
          </el-radio-button>
        </el-radio-group>
      </el-form-item>
    </div>

    <template v-if="!showAllPorts">
      <ol class="font-weight-300 fs-1-4rem">
        <i18n path="azure.express-route-html"
          tag="li">
          <template #link>
            <a href="https://docs.microsoft.com/en-us/azure/expressroute/expressroute-config-samples-routing"
              target="_blank">{{ $t('azure.q-in-q') }}</a>
          </template>
        </i18n>
        <i18n path="azure.peering-html"
          tag="li">
          <template #link>
            <a href="https://docs.microsoft.com/en-us/azure/expressroute/expressroute-circuit-peerings"
              target="_blank">{{ $t('azure.ms-doc') }}</a>
          </template>
        </i18n>
      </ol>
      <p class="fs-1-4rem">
        <a href="https://docs.microsoft.com/en-us/azure/expressroute/expressroute-howto-circuit-portal-resource-manager"
          target="_blank"
          rel="noopener">{{ $t('azure.get-service-key') }}</a>
      </p>

      <el-form-item ref="serviceKeyInput"
        prop="serviceObj.bEnd.partnerConfig.serviceKey"
        :label="$t('azure.azure-service-key')"
        label-position="top">
        <el-input v-model="key"
          v-loading="checking"
          type="text"
          placeholder="00000000-0000-0000-0000-000000000000"
          data-demo="197d927b-90bc-4b1b-bffd-fca17a7ec735"
          name="azureKey"
          data-testid="azureKey" />
      </el-form-item>

      <div class="text-align-center height-2-2rem">
        <div v-if="knownKey"
          class="color-success"
          data-name="azureKeySuccess">
          <i class="fas fa-check-circle"
            aria-hidden="true" /> {{ $t('connections.valid-service-key') }}
        </div>
        <div v-else-if="checking"
          class="color-warning">
          {{ $t('connections.verifying-key') }}
        </div>
        <div v-else
          class="color-danger"
          data-name="azureKeyFail">
          {{ error }}
        </div>
      </div>

      <el-collapse-transition>
        <div v-if="knownKey">
          <el-form-item :label="$t('connections.azure-ports')"
            prop="serviceObj.bEnd.productUid">
            <br>
            <div class="mx-2 icon-key">
              <div class="flex-row-centered">
                <mu-mega-icon class="azure-status-icon"
                  icon="SingleKey" />
                <p class="m-0 ml-1">
                  {{ $t('connections.azure-key-unused-key') }}
                </p>
              </div>
              <div class="flex-row-centered">
                <mu-mega-icon class="azure-status-icon"
                  icon="MultipleKey" />
                <p class="m-0 ml-1">
                  {{ $t('connections.azure-key-used-key') }}
                </p>
              </div>
            </div>
            <div class="port-select-holder">
              <el-table ref="portSelectTable"
                :data="ports"
                highlight-current-row
                max-height="450"
                :show-header="true"
                :empty-text="message"
                row-class-name="port-row"
                class="port-select-table full-width collapsed-border hide-table-header"
                @row-click="handlePortSelect">
                <el-table-column property="port"
                  label="Port"
                  min-width="100">
                  <template #default="{row, $index}">
                    <div :data-name="`port-${$index}`">
                      <div class="port-cell"
                        data-testid="port-cell"
                        :data-service-name="row.name"
                        :data-diversity-zone="row.diversityZone || 'none'">
                        <div class="height-2-5rem">
                          <mu-mega-icon icon="PORT"
                            class="icon" />
                        </div>
                        <div>
                          <div class="port-header">
                            {{ row.name }}
                          </div>
                          <div>{{ row.description }}</div>
                        </div>
                        <el-tooltip v-if="hasKeyBeenUsedForPort(row)"
                          placement="top"
                          class="ml-auto">
                          <div slot="content"
                            class="flex-row-centered">
                            <mu-mega-icon class="azure-status-icon mr-1"
                              icon="MultipleKey" />
                            {{ $t('connections.azure-key-used') }}
                          </div>
                          <mu-mega-icon class="azure-status-icon"
                            data-testid="MultipleKey"
                            icon="MultipleKey" />
                        </el-tooltip>
                        <el-tooltip v-else
                          placement="top"
                          class="ml-auto">
                          <div slot="content"
                            class="flex-row-centered">
                            <mu-mega-icon class="azure-status-icon mr-1"
                              icon="SingleKey" />
                            {{ $t('connections.azure-key-unused') }}
                          </div>
                          <mu-mega-icon class="azure-status-icon"
                            data-testid="SingleKey"
                            icon="SingleKey" />
                        </el-tooltip>
                        <div v-if="row.diversityZone">
                          <i class="far fa-dot-circle diversity-icon"
                            :class="`diversity-color-${row.diversityZone}`"
                            :title="$t(`services.${row.diversityZone}-zone`)" />
                        </div>
                      </div>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-form-item>
        </div>
      </el-collapse-transition>

      <el-collapse-transition>
        <div v-if="!message && knownKey && productUid && aEndIsMcr">
          <div v-if="isPeerDisabled">
            <p class="font-weight-500 m-0">
              {{ $t('azure.peering-type') }}: {{ peeringType.join(', ') }}
            </p>
            <p class="m-0">
              {{ $t('azure.peering-type-explanation') }}
            </p>
          </div>
          <el-form-item v-else
            :label="$t('azure.peering-type')"
            prop="serviceObj.bEnd.partnerConfig.peers">
            <el-checkbox-group v-model="peeringType">
              <el-checkbox label="private">
                {{ $t('general.private') }}
              </el-checkbox>
              <el-checkbox label="microsoft">
                {{ $t('azure.microsoft') }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </div>
      </el-collapse-transition>
    </template>

    <template v-else>
      <el-form ref="portPriceForm"
        :model="portPriceForm"
        :rules="portPriceRules">
        <el-form-item :label="$t('connections.select-destination-port-title')"
          prop="productUid">
          <el-select v-model="countryFilter"
            clearable
            class="dont-color full-width"
            :placeholder="$t('general.country-filter')"
            name="countryFilter">
            <el-option v-for="country in countries"
              :key="country"
              :value="country">
              {{ country }}
            </el-option>
          </el-select>

          <div class="flex-row-centered zone-table-header">
            <div>{{ $t('services.diversity-zone') }}:</div>
            <div>
              <el-radio-group v-model="zoneFilter"
                size="mini">
                <el-radio-button :label="null">
                  {{ $t('general.all') }}
                </el-radio-button>
                <el-radio-button :label="G_DIVERSITY_ZONE_RED"
                  class="dz-dot">
                  <i class="far fa-dot-circle diversity-icon diversity-color-red"
                    :title="$t(`services.red-zone`)" />
                </el-radio-button>
                <el-radio-button :label="G_DIVERSITY_ZONE_BLUE"
                  class="dz-dot">
                  <i class="far fa-dot-circle diversity-icon diversity-color-blue"
                    :title="$t(`services.blue-zone`)" />
                </el-radio-button>
              </el-radio-group>
            </div>
          </div>

          <div class="port-select-holder">
            <el-table ref="allPortSelectTable"
              :data="allPorts"
              highlight-current-row
              max-height="350"
              :show-header="true"
              :empty-text="$t('connections.no-available-target-ports')"
              row-class-name="port-row"
              class="port-select-table full-width collapsed-border hide-table-header"
              @row-click="handleAllPortSelect">
              <el-table-column property="port"
                label="Port"
                min-width="100">
                <template #default="{row}">
                  <div class="port-cell"
                    data-testid="port-cell"
                    :data-service-name="row.title"
                    :data-location="row._location && row._location.formatted && row._location.formatted.short"
                    :data-diversity-zone="row.diversityZone">
                    <div class="height-2-5rem">
                      <mu-mega-icon icon="PORT"
                        class="icon" />
                    </div>
                    <div>
                      <div class="port-header">
                        {{ row.title }}
                      </div>
                      <div v-if="row._location">
                        {{ row._location.formatted.short }}
                      </div>
                    </div>
                    <div v-if="row.diversityZone"
                      class="ml-auto">
                      <i class="far fa-dot-circle diversity-icon"
                        :class="`diversity-color-${row.diversityZone}`"
                        :title="$t(`services.${row.diversityZone}-zone`)" />
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-form-item>

        <el-collapse-transition>
          <input-ratelimit v-if="portPriceForm.productUid"
            ref="rateLimitInput"
            v-model="portPriceForm.rateLimit"
            prop="rateLimit"
            :label="$t('services.rate-limit')"
            label-width="130px"
            :help-text="$t('connections.vxc-rate-limit-help')"
            :max-rate-limit="maxRateLimit" />
        </el-collapse-transition>

        <p class="fs-1-6rem">
          <span v-if="price"><strong>{{ $t('pricebook.monthly-rate') }}:</strong> <span data-name="monthlyRate">{{ price.monthlyRate | formatInCurrency(price.currency) }}</span> ({{ $t('pricebook.excludes-tax') }})</span><span v-else>&nbsp;</span>
        </p>

        <p>{{ $t('azure.back-to-service-key') }}</p>
      </el-form>
    </template>
  </div>
</template>

<script>
import InputRatelimitComponent from '@/components/InputRatelimit.vue'
import { maxVXCSpeed, moneyFilter } from '@/helpers'
import captureSentryError from '@/utils/CaptureSentryError.js'
import { scopedPriceBook } from '@/utils/priceBook.js'
import { mapGetters } from 'vuex'
import sdk from '@megaport/api-sdk'

export default {
  components: {
    'input-ratelimit': InputRatelimitComponent,
  },

  filters: {
    formatInCurrency: moneyFilter,
  },

  inject: ['disabledFeatures', 'isFeatureEnabled'],

  props: {
    aEnd: {
      type: Object,
      required: true,
    },
    bEnd: {
      type: Object,
      required: true,
    },
  },

  data() {
    const validateRatelimit = (_rule, _value, callback) => {
      if (this.$refs.rateLimitInput && this.$refs.rateLimitInput.invalid) {
        callback(new Error(this.$refs.rateLimitInput.invalid))
      } else {
        callback()
      }
    }

    return {
      portPriceForm: {
        productUid: null,
        rateLimit: null,
      },
      portPriceRules: {
        productUid: { required: true, message: this.$t('connections.please-select-port'), trigger: 'change' },
        rateLimit: { required: true, validator: validateRatelimit, trigger: 'change' },
      },
      price: null,
      hasKey: true,
      key: null,
      knownKey: null,
      checking: false,
      productUid: null,
      error: null,
      showAllPorts: false,
      countryFilter: '',
      zoneFilter: null,
      peeringType: ['private'],
    }
  },

  computed: {
    ...mapGetters('Services', ['myConnections', 'targetPorts', 'findPort']),

    showPricing() {
      return !this.disabledFeatures.showPrices && this.isFeatureEnabled('PRICING_VISIBLE')
    },
    aEndIsMcr() {
      const aEndService = this.findPort(this.aEnd.productUid)
      return aEndService?.productType === this.G_PRODUCT_TYPE_MCR2
    },
    isPeerDisabled() {
      return this.knownKey.peers && this.knownKey.peers.length > 0
    },
    ports() {
      if (!this.knownKey?.megaports) return []

      return this.knownKey.megaports.map(keyPort => {
        const targetPort = this.targetPorts.find(p => p.productUid === keyPort.productUid)
        // If a matching target port is found, add its diversity zone to the port object
        // This will be used to show the diversity zone in the table
        if (targetPort) keyPort.diversityZone = targetPort.diversityZone
        return keyPort
      })
    },
    allPorts() {
      return this.targetPorts
        .filter(p => p.connectType === 'AZURE' && p.vxcPermitted === true)
        .filter(port => {
          if (this.countryFilter && this.countryFilter !== port._location.country) return false
          if (this.zoneFilter && this.zoneFilter !== port.diversityZone) return false
          return true
        })
        .sort((a, b) => {
          if (a.title > b.title) return 1
          if (a.title < b.title) return -1
          return 0
        })
    },
    countries() {
      const azurePorts = this.targetPorts.filter(p => p.connectType === 'AZURE')
      if (azurePorts.length > 0)
        return [...new Set(azurePorts.map(p => p._location.country))].sort()
      return []
    },
    message() {
      if (!this.knownKey) return ''
      if (!this.ports.length) {
        return this.$t('connections.no-available-ports-key')
      }
      return ''
    },
    maxRateLimit() {
      const aPort = this.$store.getters['Services/findPort'](this.aEnd.productUid)
      const bPort = this.$store.getters['Services/findPort'](this.portPriceForm.productUid)

      return maxVXCSpeed(aPort, bPort)
    },
  },

  watch: {
    showAllPorts() {
      if (this.showAllPorts) {
        this.key = null
      } else {
        this.productUid = null
      }
      this.$emit('update', { showAllPorts: this.showAllPorts })
    },
    peeringType(n, o) {
      if (this.peeringType.length < 1) {
        this.peeringType = o
      }
      this.updatePartnerConfig()
    },
    productUid() {
      this.updatePartnerConfig()
    },
    key() {
      this.key = this.key ? this.key.trim() : null
      this.checkKey()
      this.updatePartnerConfig()
    },
    portPriceForm: {
      immediate: true,
      deep: true,
      handler() {
        this.showPricing && this.calculatePrice()
      },
    },
  },

  mounted() {
    if (this.bEnd.partnerConfig && this.bEnd.partnerConfig.serviceKey) {
      this.key = this.bEnd.partnerConfig.serviceKey
    }

    if (this.bEnd.partnerConfig && this.bEnd.partnerConfig.peers && this.bEnd.partnerConfig.peers.length > 0) {
      this.peeringType = this.bEnd.partnerConfig.peers.map(peer => peer.type).sort()
    }

    this.productUid = this.bEnd.productUid
    this.portPriceForm.productUid = this.bEnd.productUid

    if (this.portPriceForm.productUid && !this.key) {
      this.showAllPorts = true
    }
    this.$nextTick(() => {
      // If we are showing all ports, then we can immediately display from the full list.
      if (this.showAllPorts && this.portPriceForm.productUid && this.$refs.allPortSelectTable) {
        const index = this.allPorts.findIndex(port => {
          return port.productUid === this.portPriceForm.productUid
        })
        if (index !== -1) {
          // Set the current row
          this.$refs.allPortSelectTable.setCurrentRow(this.allPorts[index])
          // Scroll the selected row to visible - nasty hacky way to do it, but it works
          const targetTop = this.$refs.allPortSelectTable.$el.querySelectorAll('.el-table__body tr')[index].getBoundingClientRect().top
          const containerTop = this.$refs.allPortSelectTable.$el.querySelector('.el-table__body').getBoundingClientRect().top
          const scrollParent = this.$refs.allPortSelectTable.$el.querySelector('.el-table__body-wrapper')
          scrollParent.scrollTop = targetTop - containerTop
        }
      }
    })
  },

  methods: {
    scopedPriceBook,

    calculatePrice() {
      if (!this.portPriceForm.productUid || !this.portPriceForm.rateLimit) {
        this.price = null

        return true
      }

      this.price = null

      const aPort = this.$store.getters['Services/findPort'](this.aEnd.productUid)
      const bPort = this.$store.getters['Services/findPort'](this.portPriceForm.productUid)

      this.scopedPriceBook()
        .vxc(
          aPort.locationId,
          bPort.locationId,
          this.portPriceForm.rateLimit,
          null,
          aPort.productType,
          bPort.connectType,
          aPort.buyoutPort,
          null
        )
        .then(res => {
          this.price = res
        })
        .catch(() => {
          // Error handled by the pricebook module.
          this.price = null
        })
    },
    handlePortSelect(val) {
      // If nothing was selected or the same item as last time was clicked, then deselect.
      if (!val || (this.productUid && this.productUid === val.productUid)) {
        this.$refs.portSelectTable.setCurrentRow()
        this.productUid = null
      } else {
        this.productUid = val.productUid
      }
    },
    handleAllPortSelect(val) {
      // If nothing was selected or the same item as last time was clicked, then deselect.
      if (!val || (this.portPriceForm.productUid && this.portPriceForm.productUid === val.productUid)) {
        this.$refs.allPortSelectTable.setCurrentRow()
        this.portPriceForm.productUid = null
      } else {
        this.portPriceForm.productUid = val.productUid
      }
      this.$refs.portPriceForm.validateField('productUid')
    },
    updatePartnerConfig() {
      const config = {
        productUid: this.productUid,
        vlan: this.knownKey ? this.knownKey.vlan : null,
        partnerConfig: {
          bVlan: this.knownKey ? this.knownKey.vlan : null,
          connectType: 'AZURE',
          serviceKey: this.key,
          validKey: this.knownKey !== null,
          maxRateLimit: this.knownKey ? this.knownKey.bandwidth : null,
        },
      }
      if (this.aEndIsMcr) {
        const peers = this.peeringType.map(t => {
          return {
            type: t,
          }
        })
        config.partnerConfig.peers = peers
      }
      this.$emit('update', config)
      this.$refs.serviceKeyInput?.elForm.validateField('serviceObj.bEnd.partnerConfig.serviceKey')
    },
    checkKey() {
      const tmpUid = this.productUid
      this.productUid = null
      this.knownKey = null
      this.error = null
      const serviceKey = (this.key || '').replace(/(^\s*|[^a-z0-9]|\s*$)/gim, '')
      if (serviceKey.length === 32) {
        this.checking = true

        sdk.instance
          .product()
          .azure(this.key)
          .then(azureData => {
            this.checking = false
            this.knownKey = azureData

            if (this.knownKey.peers.length > 0) {
              this.peeringType = this.knownKey.peers.map(peer => peer.type).sort()
            }

            if (azureData.megaports) {
              this.productUid = tmpUid
            }

            this.$nextTick(() => {
              // See if the selected port was in the list, and if so, select it.
              if (this.knownKey.megaports && this.productUid && this.$refs.portSelectTable) {
                const index = this.knownKey.megaports.findIndex(port => {
                  return port.productUid === this.productUid
                })
                if (index !== -1) {
                  // Set the current row
                  this.$refs.portSelectTable.setCurrentRow(this.knownKey.megaports[index])
                  // Scroll the selected row to visible - nasty hacky way to do it, but it works
                  const targetTop = this.$refs.portSelectTable.$el.querySelectorAll('.el-table__body tr')[index].getBoundingClientRect().top
                  const containerTop = this.$refs.portSelectTable.$el.querySelector('.el-table__body').getBoundingClientRect().top
                  const scrollParent = this.$refs.portSelectTable.$el.querySelector('.el-table__body-wrapper')
                  scrollParent.scrollTop = targetTop - containerTop
                } else {
                  this.productUid = null
                }
              }
            })

            this.updatePartnerConfig()
            this.error = null
          })
          .catch(e => {
            this.knownKey = null
            this.checking = false
            this.error = this.$t('validations.invalid-service-key')
            this.updatePartnerConfig()
            // If it was an invalid key, then it should return with a 400 or 404 status. If not,
            // then it's some sort of other error which we want to capture.
            if (e.status !== 400 && e.status !== 404) {
              captureSentryError(e)
            }
          })
      } else {
        this.updatePartnerConfig()
      }
    },
    hasKeyBeenUsedForPort(portData) {
      // This will pick up any deployed connections
      if (portData.vxc) {
        return true
      }
      // Also check for any designed ones, but exclude this one
      for (const connection of this.myConnections) {
        if (connection.provisioningStatus === this.G_PROVISIONING_DESIGN &&
          connection.bEnd?.productUid === portData.productUid &&
          this.aEnd.productUid !== connection.aEnd.productUid) {
          return true
        }
      }
      return false
    },
  },
}
</script>

<style lang="scss" scoped>
.port-select-holder {
  background-color: #fbfbfb;
  border: 1px solid var(--card-border-color);
  border-radius: var(--border-radius-base);
  padding: 0.5rem;
  margin-top: 1rem;
  clear: both;
}
.is-error .port-select-holder {
  border-color: var(--color-danger);
}
.is-success .port-select-holder {
  border-color: var(--color-success);
}
svg.icon {
  display: inline-block;
  color: var(--color-text-regular);
  width: 2.5rem;
  height: 2.5rem;
  fill: currentColor;
}
.port-cell svg {
  margin-right: 0.5rem;
}
.icon-key {
  p {
    line-height: normal;
  }
  .azure-status-icon {
    margin: 0.25rem 0 0.25rem 0;
  }
}
.azure-status-icon {
  width: 27px;
  height: 26px;
  flex-shrink: 0;
}
</style>

<style lang="scss">
.port-select-table table tr.port-row {
  background-color: #fbfbfb;
  &:hover > td {
    background-color: #fbfbfb;
  }
}
.port-select-table.el-table td {
  padding: 0;
  border: 0;
}
.port-select-table .port-cell {
  display: flex;
  align-items: center;
  background-color: var(--color-white);
  border: 1px solid var(--card-border-color);
  border-radius: var(--border-radius-base);
  padding: 0.5rem;
  margin: 0.25rem 0;
}
.port-cell {
  background-color: var(--color-white);
  word-break: normal;
  word-wrap: break-word;
  &:hover {
    border-color: var(--color-primary-light-5);
    background-color: var(--color-primary-light-9);
  }
  .port-header {
    font-weight: 700;
  }
}
.current-row .port-cell {
  .port-header {
    color: var(--color-primary);
  }
  border-color: var(--color-primary);
  background-color: var(--color-primary-light-8);
  svg.icon {
    color: var(--color-primary);
    fill: currentColor;
  }
}
.port-select-table .el-table__body tr.current-row > td {
  background-color: #fbfbfb;
}
</style>
