<template>
  <div class="min-width-550px">
    <!-- Sub-title: Service Key -->
    <h4 class="text-align-center mb-3 font-weight-500">
      {{ $t('services.service-key') }}
    </h4>

    <!-- Service Key Search Form -->
    <el-form ref="targetServiceKeyForm"
      :model="targetServiceKeyForm"
      :rules="targetServiceKeyRules"
      @submit.native.prevent>
      <el-form-item prop="key"
        :label="$t('connections.service-key-id')"
        label-width="250px">
        <el-input v-model.trim="targetServiceKeyForm.key"
          v-loading="checking"
          :placeholder="$t('services.service-key')"
          name="serviceKey" />
      </el-form-item>
    </el-form>

    <!-- Service Key Search Feedback -->
    <div class="text-align-center height-2-2rem">
      <!-- Successful -->
      <div v-if="knownKey"
        data-name="validServiceKey"
        class="color-success">
        <i class="fas fa-check-circle"
          aria-hidden="true" /> {{ $t('connections.valid-service-key') }}
      </div>
      <!-- Searching -->
      <div v-else-if="checking"
        data-name="checkingServiceKey"
        class="color-warning">
        {{ $t('connections.verifying-key') }}
      </div>
      <!-- Error -->
      <div v-else
        data-name="serviceKeyError"
        class="color-danger">
        {{ error }}
      </div>
    </div>

    <el-collapse-transition>
      <section v-if="knownKey">
        <!-- Sub-title: Destination Found -->
        <h4 class="mt-2 mb-1 text-align-center font-weight-500">
          {{ $t('target-service-key.destination-found') }}
        </h4>

        <!-- Service Key: Key Name -->
        <simple-read-only-field :label="$t('target-service-key.key-name')"
          :value="knownKey.description"
          data-testid="service-key-name" />

        <!-- Service Key: Company -->
        <simple-read-only-field :label="$t('menu.company')"
          :value="knownKey.companyName"
          data-testid="service-key-company-name" />

        <!-- Service Key: Max Speed -->
        <simple-read-only-field :label="$t('target-service-key.max-speed')"
          :value="`${knownKey.maxSpeed} Mbps`"
          data-testid="service-key-max-speed" />
      </section>
    </el-collapse-transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import sdk from '@megaport/api-sdk'

import captureSentryError from '@/utils/CaptureSentryError.js'
import { deepClone } from '@/helpers.js'

import ConnectionValidator from '@/components/ConnectionValidations.js'
import SimpleReadOnlyField from '@/components/ui-components/SimpleReadOnlyField.vue'

export default {
  name: 'TargetServiceKey',

  components: {
    'simple-read-only-field': SimpleReadOnlyField,
  },

  props: {
    value: {
      type: Object,
      required: false,
      default() {
        return {}
      },
    },
    aEnd: {
      type: Object,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      connectionValidator: new ConnectionValidator(this),
      knownKey: null,
      checking: false,
      productUid: null,
      partnerConfig: null,
      error: null,
      reason: null,
      targetServiceKeyForm: {
        key: null,
      },
    }
  },

  computed: {
    ...mapGetters('Services', ['findPort']),
    targetServiceKeyRules() {
      return this.connectionValidator.validationForType('target-select-key')
    },
    aEndProductType() {
      const productType = this.findPort(this.aEnd.productUid).productType

      switch (productType) {
        case this.G_PRODUCT_TYPE_MEGAPORT:
          return this.$t('productNames.port')
        case this.G_PRODUCT_TYPE_MCR2:
          return this.$t('productNames.mcr')
        default:
          return productType
      }
    },
  },

  watch: {
    'targetServiceKeyForm.key'() {
      this.checkKey()
    },
  },

  created() {
    if (this.value.productUid) this.productUid = this.value.productUid
    if (this.value.partnerConfig) {
      this.partnerConfig = this.value.partnerConfig
      if (this.value.partnerConfig.serviceKey && this.value.partnerConfig.serviceKey.key) {
        const key = this.value.partnerConfig.serviceKey.key
        this.targetServiceKeyForm.key = key.length > 8 ? key.slice(0, 8) : key
      }
    }
  },

  methods: {
    emitUpdate(errorMessage = '') {
      const end = deepClone(this.value)
      end.productUid = this.productUid
      this.connectionValidator.setSupportingData('target-select-key', { productUid: this.productUid, errorMessage })
      this.$refs.targetServiceKeyForm.validateField('key')

      if (this.partnerConfig) {
        end.partnerConfig = deepClone(this.partnerConfig)
      }
      if (this.knownKey && this.knownKey.vlan) {
        end.vlan = this.knownKey.vlan
      }
      this.$emit('input', end)
    },
    checkKey() {
      this.productUid = null
      this.knownKey = null
      this.error = null
      this.partnerConfig = Object.assign({}, this.partnerConfig, {
        serviceKey: {
          key: this.targetServiceKeyForm.key,
        },
        maxRateLimit: null,
      })

      const serviceKey = this.targetServiceKeyForm.key.replace(/(^\s*|[^a-z0-9]|\s*$)/gim, '')
      if (serviceKey.length === 8 || serviceKey.length === 32) {
        this.checking = true
        sdk.instance
          .product()
          .getKey(this.targetServiceKeyForm.key)
          .then(keyData => {
            this.checking = false
            if (keyData.expired) {
              this.error = this.$t('connections.key-expired', { company: keyData.companyName })
              this.emitUpdate()
              return
            }
            if (keyData.singleUse && !keyData.valid) {
              this.error = this.$t('connections.key-used', { company: keyData.companyName })
              this.emitUpdate()
              return
            }
            if (!keyData.valid) {
              this.error = this.$t('connections.key-invalid', { company: keyData.companyName })
              this.emitUpdate()
              return
            }
            if (this.aEnd && keyData.productUid === this.aEnd.productUid) {
              this.error = this.$t('connections.service-key-unavailable', { product: this.aEndProductType })
              this.emitUpdate()
              return
            }

            this.knownKey = keyData

            this.$store.commit('Services/addPartnerPort', {
              companyName: keyData.productDto.companyName,
              companyUid: keyData.productDto.companyUid,
              connectType: keyData.productDto.connectType ?? 'DEFAULT',
              locationId: keyData.productDto.locationId,
              productUid: keyData.productDto.productUid,
              rank: 0,
              speed: keyData.productDto.portSpeed,
              title: keyData.productDto.productName,
              vxcPermitted: keyData.productDto.vxcPermitted,
              diversityZone: keyData.productDto.diversityZone,
            })

            this.partnerConfig = Object.assign({}, this.partnerConfig, {
              serviceKey: {
                key: keyData.key,
              },
              maxRateLimit: keyData.maxSpeed,
            })
            this.productUid = keyData.productUid
            this.emitUpdate()
          })
          .catch(e => {
            this.checking = false

            if (e.data && e.data.message) {
              this.error = e.data.message
            } else {
              this.error = this.$t('validations.invalid-service-key')
            }
            // 400 is the expected response for a bad key. Anything else
            // should be reported to the authorities.
            if (e.status !== 400 && e.status !== 403) {
              captureSentryError(e)
            }

            if (e.status === 403) {
              this.emitUpdate(this.$t('validations.insufficient-privileges'))
            } else {
              this.emitUpdate()
            }
          })
      } else {
        this.emitUpdate()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.min-width-550px {
  min-width: 550px;
}
</style>
